
import axios from "axios";
import { getToken, removeUserSession, setUserSession } from '../utils/common';
import createDataContext from './createDataContext';

const INITIAL_STATE = {
  errorMessage: '',
  loading: false,
  user: {
    valid: false
  },
  token: null,
  nav: 1,
  redirectTo: '',
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'clear_message': {
      return { ...state, errorMessage: '' }
    }
    case 'change_waiting_value': {
      return { ...state, loading: !state.loading }
    }
    case 'change_nav_value': {
      return { ...state, nav: action.payload, redirectTo: '' }
    }
    case 'sign_in': {
      return { ...state, token: action.payload.token, user: action.payload.user, redirectTo: '', loading: false }
    }
    case 'add_error': {
      return { ...state, errorMessage: action.payload, loading: false }
    }
    case 'sign_out': {
      return {
        ...state, errorMessage: '', loading: false, user: {
          valid: false
        },
        token: null,
        nav: 1,
        redirectTo: '',
      };
    }
    case 'redirect': {
      return { ...state, redirectTo: action.payload }
    }
    default: return state;
  }
}

const clearMessage = dispatch => () => {
  dispatch({ type: 'clear_message' })
}

const changeWaitingValue = dispatch => () => {
  dispatch({ type: 'change_waiting_value' })
}

const setNavigation = dispatch => (value) => {
  dispatch({ type: 'change_nav_value', payload: value })
}

const tryLocalSignin = dispatch => async () => {
  return new Promise((resolve, reject) => {
    const myToken = getToken();
    if (myToken) {
      axios({
        url: 'https://origen-server.herokuapp.com/graphql',
        method: 'post',
        headers: {
          authorization: `Bearer ${myToken}`,
        },
        data: {
          query: `
            query profile {
              profile {
                id
                apellido
                email
                nombre
                role
                compania
                blockByLackOfPayment
                proveedorID {
                  nombre
                  id
                }
              }
            }
          `
        }
      }).then((result) => {
        const { apellido, email, id, nombre, role, compania, blockByLackOfPayment, proveedorID } = result.data.data.profile;
        setUserSession(myToken, { apellido, email, id, nombre, role, compania, blockByLackOfPayment, pID: proveedorID.id, pNombre: proveedorID.nombre, listProveedores: proveedorID });
        dispatch({ type: 'sign_in', payload: { token: myToken, user: { apellido, email, id, nombre, role, compania, blockByLackOfPayment, pID: proveedorID.id, pNombre: proveedorID.nombre, listProveedores: proveedorID } } });
        resolve(true);
      })
        .catch((error) => {
          removeUserSession();
          dispatch({ type: 'sign_out' })
          dispatch({ type: 'redirect', payload: "/login" });
          resolve(false);
        });
    } else {
      resolve(false);
    }
  })
}

const signin = dispatch => async (values) => {
  const { apellido, email, id, nombre, role, token, compania, proveedorID } = values;
  dispatch({ type: 'sign_in', payload: { token: token, user: { apellido, email, id, nombre, role, compania, pID: proveedorID.id, pNombre: proveedorID, listProveedores: proveedorID } } })
  setUserSession(token, { apellido, email, id, nombre, role, compania, pID: proveedorID.id, pNombre: proveedorID, listProveedores: proveedorID })
  dispatch({ type: 'redirect', payload: "/home" });
  return;
}

const signout = dispatch => () => {
  removeUserSession();
  dispatch({ type: 'sign_out' })
  dispatch({ type: 'redirect', payload: "/login" })
}

const setError = dispatch => (error) => {
  dispatch({ type: 'add_error', payload: `${error}` })
}

const setRedirect = dispatch => (path) => {
  dispatch({ type: 'redirect', payload: path })
}

export const { Provider, Context } = createDataContext(authReducer,
  {
    clearMessage,
    changeWaitingValue,
    tryLocalSignin,
    setNavigation,
    signin,
    signout,
    setError,
    setRedirect,
  },
  INITIAL_STATE
)