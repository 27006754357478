export const GET_PRUEBAS = `
  query GetTests {
    getTests {
      id
      nombre
      codigoPrueba
    }
  }
`;

export const GET_USUARIOS = `
  query getUsers {
    getUsers {
      id
      email
      nombre
      apellido
      role
      approved
    }
  }
`;

export const GET_NUEVO_ESTUDIO_PARAMS = `
query getNewEstudioParams {
  getAreasEstudio {
    id
    nombre
  }
  getTiposDeMuestra {
    nombre
    id
  }
}
`;

export const GET_PRUEBA = `
query getTest($id: ID!) {
  getTest(id: $id) {
    nombre
    id
    codigoPrueba
  }
}
`;

export const FIND_CLIENT_BY_CI = `
  query GetClient($input: SearchClient) {
    getClient(input: $input) {
      id
      nombres
      apellidos
      ci
      passport
      fecha_nacimiento
      telefono
      municipio
      departamento
      direccion
      sexo 
      edad
    }
  }
`;

export const GET_PACIENTES = `
query getClients($limit: Int) {
  getClients(limit: $limit) {
    id
    _id
    nombres
    apellidos
    ci
    passport
    fecha_nacimiento
    sexo
    edad
    telefono
    municipio
    departamento
    direccion
  }
}
`;

export const GET_PACIENTE = `
query getClient($input: SearchClient) {
  getClient(input: $input) {
    id
    nombres
    apellidos
    ci
    passport
    fecha_nacimiento
    sexo
    edad
    telefono
    municipio
    departamento
    direccion
  }
}
`;

export const GET_FICHAS_BY_TEST = `
query getFichasByTest($id: ID) {
  getFichasByTest(testId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
    }
  }
}
`;

export const GET_FICHAS_BY_TEST_2 = `
query getFichasByTestOptimized($id: ID, $noInformes: String, $startDate: String, $endDate: String) {
  getFichasByTestOptimized(testId: $id, noInformes: $noInformes, startDate: $startDate, endDate: $endDate) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
  }
}
`;

export const GET_FICHAS_EDIT_DATE_REPORT = `
query getFichasByTestEditReport($id: ID, $noInformes: String, $startDate: String, $endDate: String) {
  getFichasByTestEditReport(testId: $id, noInformes: $noInformes, startDate: $startDate, endDate: $endDate) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    tipoDeMuestra
    fechaReporte
    clientID {
      nombres
      apellidos
      ci
      passport
    }
  }
}
`;

export const GET_FICHAS_BY_TEST_POR_CONCILIAR = `
query getFichasByTestPorConciliar($id: ID, $noInformes: String, $startDate: String, $endDate: String, $tipoPaciente: ID) {
  getFichasByTestPorConciliar(testId: $id, noInformes: $noInformes, startDate: $startDate, endDate: $endDate, tipoPaciente: $tipoPaciente) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    noComprobante
    montoPagado
    pagoStatus
    noFactura
    fechaFactura
    ordenNumero
    numeroDeAutorizacion
    clientID {
      nombres
      apellidos
      ci
      passport
    }
  }
}
`;

export const BUSCAR_ORDENES_SIMPLE = `
query buscarOrdenConciliacion($params: OrdenConciliacionInput) {
  buscarOrdenConciliacion(params: $params) {
    id
    ordenNumero
    totalAPagar
    estatusPago
    fechaSolicitud
    formaDePago
    clientID {
      nombres
      apellidos
      ci
      passport
    }
    proveedorID {
      nombre
		}   
  }
}
`;

export const BUSCAR_ORDENES_COMPLEX = `
query buscarOrdenConciliacionWithEstudio($params: OrdenConciliacionInput) {
  buscarOrdenConciliacionWithEstudio(params: $params) {
    id
    ordenNumero
    totalAPagar
    estatusPago
    fechaSolicitud
    formaDePago
    clientID {
      nombres
      apellidos
      ci
      passport
    }
    proveedorID {
      nombre
		}
    
  }
}
`;

export const GET_FICHAS_BY_TEST_PENDING = `
query getFichasByTestPendientes($id: ID) {
  getFichasByTestPendientes(testId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
      nombre
    }
  }
}
`;

export const GET_FICHA = `
query getFicha($id: ID!) {
  getFicha(id: $id) {
    id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    inicioSintomas
    fechaReporte
    observacion
    testID {
      nombre
      codigoPrueba
      id
    }
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
      municipio
      departamento
      direccion
      id
    }
  }
}
`;

export const GET_FICHAS_BY_EMPRESA = `
query getFichasPorEmpresa($busqueda: [String], $testId: ID) {
  getFichasPorEmpresa(busqueda: $busqueda, testId: $testId) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
  }
}
`;

export const GET_FICHAS_MY_EMPRESA = `
query getFichasMyEmpresa($testId: ID, $query: QueryFichasInput) {
  getFichasMyEmpresa(testId: $testId, query: $query) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
      nombre
    }
  }
}
`;

export const GET_FICHAS_BY_PROVIDER = `
query getFichasPorEmpresaOptimizado($proveedorId: ID, $testId: ID, $query: QueryFichasInput) {
  getFichasPorEmpresaOptimizado(proveedorId: $proveedorId, testId: $testId, query: $query) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
      nombre
    }
  }
}
`;

export const GET_ORDENES_BY_EMPRESA = `
query getOrdenesByEmpresa($proveedorID: ID, $query: QueryInputOrdenes) {
  getOrdenesByEmpresa(proveedorID: $proveedorID, query: $query) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    isSigned
    fechaReporte
    precio
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      nombre
    }
    estudioID {
      nombre
      description
      metodo
    }
    camposEstudio {
      nombre
      label
      valor
      referencia
      unidad
    }
    ordenID {
      nombreMedico
      clientID {
        nombres
        apellidos
        ci
        passport
        fecha_nacimiento
        sexo
        telefono
      }
      proveedorID {
        nombre
      }
    }
  }
}
`;

export const GET_ORDENES_MY_EMPRESA = `
query getOrdenesMyEmpresa($query: QueryInputOrdenes) {
  getOrdenesMyEmpresa(query: $query) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    isSigned
    fechaReporte
    precio
    attachmentFiles {
      id
      name
      mimeType
      url
    }
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      nombre
      id
    }
    estudioID {
      nombre
      description
      metodo
      id
      isDerivable
    }
    camposEstudio {
      nombre
      label
      valor
      referencia
      unidad
    }
    ordenID {
      nombreMedico
      clientID {
        nombres
        apellidos
        ci
        passport
        fecha_nacimiento
        sexo
        telefono
      }
      proveedorID {
        nombre
      }
    }
  }
}
`;

export const GET_EMPRESAS_STATS = `
query getFichasPorEmpresaStats($busqueda: [String]!, $testId: ID!) {
  getFichasPorEmpresaStats(busqueda: $busqueda, testId: $testId)
}
`;

export const GET_FICHAS_BY_CLIENT = `
query getFichasByClient($id: ID) {
  getFichasByClient(clientId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      nombre
      codigoPrueba
    }
  }
}
`;

export const GET_EMPRESAS = `
query getEmpresas {
  getEmpresas {
    compania
    keywords
    email
    nombre
    apellido
    telefono
    id
    approved
  }
}
`;

export const GET_PERFILES = `
query getPerfiles($params: PerfilQueryInput) {
  getPerfiles(params: $params) {
    id
    nombre
    estatus
    estudios {
      id
      nombre
      precio
    }
    proveedorID {
      nombre
      id
    }
  }
}
`;

export const GET_PERFIL = `
query getPerfil($id: ID!) {
  getPerfil(id: $id) {
    id
    nombre
    estatus
    estudios {
      id
      nombre
      precio
    }
  }
}
`;

export const GET_MUESTRAS = `
query getTiposDeMuestra {
  getTiposDeMuestra {
    nombre
    description
    estatus
    id
  }
}
`;

export const GET_MUESTRA = `
query getTipoDeMuestra($id: ID!) {
  getTipoDeMuestra(id: $id) {
    nombre
    description
    estatus
    id
  }
}
`;

export const GET_ESTUDIO = `
query getEstudio($id: ID!) { 
  getEstudio(id: $id) {
    id
    nombre
    codigo
    description
    metodo
    estatus
    areaDeEstudioID {
      nombre
      id
    }
    tipoDeMuestras {
      id
      nombre
    }
    camposEstudio {
      nombre
      label
      valor
      tipo
      opciones
      valorPorDefecto
      referencia
      unidad
      tipo_hijo
      father
      value_father
      esCambiante
      formulaValidation
    },
  }
}
`;

export const GET_ESTUDIOS_SIMPLE = `
query getEstudios {
  getEstudios {
    id
    nombre
    codigo
  }
}
`;

export const GET_EMAILS_PROVIDER = `
query findUsersOfProvider($id: ID!) {
  findUsersOfProvider(id: $id) {
    email
  }
}
`;

export const SEND_EMAIL_NEW_RESULTS = `
query sendEmailNewResultsAvailable($emails: [String], $compania: String) {
  sendEmailNewResultsAvailable(emails: $emails, compania: $compania)
}
`;

export const REPORTES_ORDENES = `
query generateReportOrdenes($start: String!, $end: String!, $tipoInforme: String, $testId: ID, $proveedorID: ID) {
  generateReportOrdenes(start: $start, end: $end, tipoInforme: $tipoInforme, testId: $testId, proveedorID: $proveedorID) {
    fechaSolicitud
    ordenNo
    codigoEstudio
    codigoInterno
    estudioNumero
    precio
    fechaReporte
    camposEstudio {
      nombre
      valor
      unidad
    }
    ordenID {
      clientID {
        nombres
        apellidos
        ci
        passport
        telefono
        fecha_nacimiento
        direccion
        departamento
        municipio
      }
      totalAPagar
      estatusPago
      formaDePago
      NoFactura
      nroOrdenDeCompra
      contratista
      nroAutorizacion
      fechaFactura
      fechaDePago
      referenciaDePago
      proveedorID {
        nombre
      }
    }
    estudioID {
      nombre
    }
  }
}
`;

export const GET_ESTUDIOS = `
query getEstudios {
  getEstudios {
    id
    nombre
    codigo
    description
    metodo
    estatus
    areaDeEstudioID {
      nombre
      id
    }
    tipoDeMuestras {
      id
      nombre
    }
    camposEstudio {
      nombre
      label
      valor
      tipo
      opciones
      valorPorDefecto
      referencia
      unidad
      tipo_hijo
      father
      value_father
      esCambiante
      formulaValidation
    }
  }
}
`;

export const GET_EMPRESA = `
query getEmpresa($id: ID!) {
  getEmpresa(id: $id) {
    compania
    keywords
    email
    nombre
    apellido
    id,
    approved,
    telefono
    proveedorID {
      nombre
      id
    }
  }
}
`;

export const GET_FICHAS_PACIENTE_PUBLIC = `
query getFichasByClientPublic($ci: String, $fechaNacimiento: String) {
  getFichasByClientPublic(ci: $ci, fechaNacimiento: $fechaNacimiento) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    pagoStatus
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
      nombre
    }
  }
}
`;

export const GET_ORDEN_DETALLES_PUBLIC = `
query getEstudiosPorOrdenPublic($keyHash: String) {
  getEstudiosPorOrdenPublic(keyHash: $keyHash) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    isSigned
    fechaReporte
    precio
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      nombre
    }
    estudioID {
      nombre
      description
      metodo
    }
    camposEstudio {
      nombre
      label
      valor
      referencia
      unidad
    }
    ordenID {
      nombreMedico
      clientID {
        nombres
        apellidos
        ci
        passport
        fecha_nacimiento
        sexo
        telefono
      }
      proveedorID {
        nombre
      }
    }
  }
}
`;

export const NOTIFY_EMPRESA = `
query notifyCompany($fichaID: ID!) {
  notifyCompany(fichaID: $fichaID)
}
`;

export const INFORMES = `
query generateReport($start: String, $end: String, $tipoInforme: String, $testId: ID, $tipoPaciente: ID) {
  generateReport(start: $start, end: $end, tipoInforme: $tipoInforme, testId: $testId, tipoPaciente: $tipoPaciente) {
    codigo
    fechaSolicitud
    tipoFicha
    noFactura
    montoPagado
    pagoStatus
    noComprobante
    conciliacionMadeBy
    inicioSintomas
    resultado
    fechaReporte
    fechaFactura
    clientID {
      nombres
      apellidos
      ci
      passport
      direccion
      departamento
      municipio
      fecha_nacimiento
      telefono
    }  
  } 
}
`;

export const NOTIFY_ACCOUNT_ENABLED = `
query notifyAccountVerified($id: ID) {
  notifyAccountVerified(id: $id) 
}
`;

export const NOTIFY_COMPANY_NEW_RESULTS = `
query notifyNewResults($id: ID) {
  notifyNewResults(id: $id) 
}
`;

export const GET_AREAS_LAB = `
query getAreasEstudio {
  getAreasEstudio {
    nombre
    id
  }
}
`;

export const GET_AREA_LAB = `
query getAreaEstudio($id: ID!) {
  getAreaEstudio(id: $id) {
    nombre
    id
  }
}
`;

export const GET_PROVEEDORES = `
query getProveedores {
  getProveedores {
    nombre
    estatus
    id
    tipo
  }
}
`;

export const GET_PROVEEDORES_BY_TYPE = `
query getProveedoresByTipo($tipo: [String]) {
  getProveedoresByTipo(tipo: $tipo) {
    id
    nombre
    tipo
  }
}
`;

export const GET_PROVEEDOR = `
query getProveedor($id: ID!) {
  getProveedor(id: $id) {
    nombre
    description
    estatus
    id
    tipo
    keywords
  }
}
`;

export const ESTADISTICAS_PROVEEDOR = `
query estadisticasPorProveedor($proveedorID: ID) {
  estadisticasPorProveedor(proveedorID: $proveedorID) {
    _id
    count
  }
  getProveedorStats(proveedorID: $proveedorID) {
    _id
    count
  }
}
`;

export const GET_PRECIOS_POR_PROVEEDOR = `
query getPreciosPorProveedor($id: ID!) {
  getPreciosPorProveedor(id: $id) {
    id
    precio
    estudioID {
      id
      nombre
    }
  }
}
`;

export const GET_PRECIOS_POR_PROVEEDORES = `
query getPreciosPorProveedores($id: [ID]) {
  getPreciosPorProveedores(id: $id) {
    id
    precio
    estudioID {
      id
      nombre
    }
  }
}
`;

export const GET_INITIAL_DATA_FILTRO_ORDENES = `
  query getInitialData {
    getProveedores {
      nombre
      estatus
      id
      tipo
    }
    getEstudios {
      id
      nombre
      estatus
    }
  }
`;

export const GET_ESTUDIOS_DISPONIBLES_POR_PROVEEDOR = `
query getPreciosPorProveedor($id: ID!) {
  getPreciosPorProveedor(id: $id) {
    id
    estudioID {
      id
      nombre
    }
  }
}
`;

export const GET_PRECIOS_POR_PROVEEDOR_ORDEN_LITE = `
query getPreciosPorProveedor($id: ID!) {
  getPreciosPorProveedor(id: $id) {
    id
    precio
    estudioID {
      id
    }
  }
}
`;

export const GET_PRECIOS_POR_PROVEEDOR_ORDEN = `
query getPreciosPorProveedor($id: ID!) {
  getPreciosPorProveedor(id: $id) {
    id
    precio
    estudioID {
      id
      nombre
      description
      metodo
      codigo
      tipoDeMuestras {
        nombre
        id
      }
      areaDeEstudioID {
        nombre
        id
      }
      camposEstudio {
        nombre
        label
        valor
        tipo
        opciones
        valorPorDefecto
        referencia
        unidad
        tipo_hijo
        father
        value_father
        esCambiante
        formulaValidation
      }
    }
  }
}
`;

export const GET_PRECIOS_POR_PROVEEDOR_AND_PERFILES = `
query getPreciosAndPerfiles($proveedorID: ID!, $params: PerfilQueryInput) {
  getPreciosPorProveedor(id: $proveedorID) {
    id
    precio
    estudioID {
      id
      nombre
      description
      metodo
      codigo
      tipoDeMuestras {
        nombre
        id
      }
      areaDeEstudioID {
        nombre
        id
      }
      camposEstudio {
        nombre
        label
        valor
        tipo
        opciones
        valorPorDefecto
        referencia
        unidad
        tipo_hijo
        father
        value_father
        esCambiante
        formulaValidation
      }
    }
  }
  
  getPerfiles(params: $params) {
    id
    nombre
    estudios {
      id
      nombre
      precio
    }
  }
  
}
`;

export const SEARCH_PACIENTE = `
query searchClient($input: SearchClient) {
  searchClient(input: $input) {
    nombres
    apellidos
    id
    ci
    passport
    fecha_nacimiento
  }
}
`;
export const SEARCH_ORDEN = `
query buscarOrden($tipo: String!, $valor: String!, $start: String, $end: String) {
  buscarOrden(tipo: $tipo, valor: $valor, start: $start, end: $end) {
    id
    fechaSolicitud
    ordenNumero
    totalAPagar
    clientID {
      nombres
      apellidos
      ci
      passport
      fecha_nacimiento
    }
    proveedorID {
      nombre
    }
  }
}
`;

export const ESTUDIOS_PARA_ASIGNACION_DE_CODIGOS = `
query getEstudiosHabilitadosParaAsignacionDeCodigos {
  getEstudiosHabilitadosParaAsignacionDeCodigos {
    id
    nombre
  }
}
`;

export const FIND_ESTUDIOS_PENDIENTES_POR_CODIGO = `
query getEstudiosSinCodigoInterno($estudioID: ID!) {
  getEstudiosSinCodigoInterno(estudioID: $estudioID) {
    id
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    fechaSolicitud
    ordenID {
      clientID {
        nombres
        apellidos
        ci
        passport
      }
    }
  }
}
`;

export const DETALLE_ORDEN_INVOICE = `
query getOrdenDetalles($id: ID!) {
  getOrdenDetalles(ordenID: $id) {
    fechaSolicitud
    fechaTomaDeMuestra
    estudioNumero
    codigoEstudio
    codigoInterno
    precio
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      id
      nombre
    }
    estudioID {
      nombre
      description
      metodo
    }
  } 
  getOrden(id: $id) {
    ordenNumero
    totalAPagar
    fechaSolicitud
    nombreMedico
    formaDePago
    referenciaDePago
    nota
    proveedorID {
      nombre
      tipo
      id
    }
    clientID {
      id
      nombres
      apellidos
      ci
      passport
      fecha_nacimiento
      sexo
      telefono
    }
  } 
}
`;

export const DETALLE_ORDEN = `
query getOrdenDetalles($id: ID!) {
  getOrdenDetalles(ordenID: $id) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    isSigned
    fechaReporte
    precio
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      id
      nombre
    }
    camposEstudio {
      nombre
      label
      valor
      opciones
      valorPorDefecto
      referencia
      unidad
      tipo_hijo
      father
      tipo
      value_father
      esCambiante
    }
    estudioID {
      id
      nombre
      description
      metodo
    }
  } 
  getOrden(id: $id) {
    id
    ordenNumero
    totalAPagar
    fechaSolicitud
    nombreMedico
    formaDePago
    referenciaDePago
    nota
    NoFactura
    fechaFactura
    fechaDePago
    nroOrdenDeCompra
    contratista
    nroAutorizacion
    proveedorID {
      nombre
      tipo
      id
    }
    clientID {
      id
      nombres
      apellidos
      ci
      passport
      fecha_nacimiento
      sexo
      telefono
    }
  } 
}
`;

export const DETALLE_EDIT_ORDEN = `
query getOrdenDetalles($id: ID!) {
  getOrdenDetalles(ordenID: $id) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    isSigned
    fechaReporte
    precio
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      id
      nombre
    }
    camposEstudio {
      nombre
      valor
      opciones
      valorPorDefecto
      referencia
      unidad
      tipo_hijo
      father
      value_father
      esCambiante
    }
    estudioID {
      id
      nombre
    }
  } 
  getOrden(id: $id) {
    id
    ordenNumero
    totalAPagar
    fechaSolicitud
    NoFactura
    estatusPago
    formaDePago
    referenciaDePago
    observacionDePago
    nombreMedico
    fechaFactura
    fechaDePago
    nroOrdenDeCompra
    contratista
    nroAutorizacion
    nota
    proveedorID {
      nombre
      tipo
      id
    }
    clientID {
      id
      nombres
      apellidos
      ci
      passport
      fecha_nacimiento
      sexo
      telefono
    }
  } 
}
`;

export const GET_ESTUDIOS_BY_USERS = `
query getOrdenesByUser($clientId: ID) {
  getOrdenesByUser(clientId: $clientId) {
    id
    totalAPagar
    ordenNumero
    fechaSolicitud
    proveedorID {
      nombre
    }
  }
}
`;

export const GET_ESTUDIOS_BY_AREA = `
query getEstudiosByAreaEstudio($areaEstudio: ID!, $estado: String) {
  getEstudiosByAreaEstudio(areaEstudio: $areaEstudio, estado: $estado) {
    id
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    fechaSolicitud
    fechaTomaDeMuestra
    fechaReporte
    estadoEstudio
    areaDeEstudioID {
      nombre
    }
    estudioID {
      nombre
      codigo
    }
    tipoDeMuestraID {
      nombre
    }
    ordenID {
      id
      clientID {
        nombres
        apellidos
        ci
        passport
        fecha_nacimiento
      }
    }
  }
}
`;

export const COUNT_ESTUDIOS_BY_AREA = `
query getCountEstudiosByAreaEstudio($areaEstudio: ID!, $estado: String) {
  getCountEstudiosByAreaEstudio(areaEstudio: $areaEstudio, estado: $estado) 
}
`;

export const DETALLE_ESTUDIO_FROM_ORDEN = `
query getEstudioPorOrden($id: ID!) {
  getEstudioPorOrden(id: $id) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    codigoInterno
    ordenNo
    isSigned
    fechaReporte
    precio
    tipoDeMuestraID {
      id
      nombre
    }
    areaDeEstudioID {
      id
      nombre
    }
    estudioID {
      id
      nombre
      description
      metodo
      camposEstudio {
        valorPorDefecto
        nombre
        esCambiante
        unidad
        opciones
        formulaValidation
        referencia
      }
      tipoDeMuestras {
        id
        nombre
      }
    }
    camposEstudio {
      nombre
      label
      valor
      tipo
      opciones
      valorPorDefecto
      referencia
      unidad
      tipo_hijo
      father
      value_father
      esCambiante
      formulaValidation
    }
    ordenID {
      nombreMedico
      clientID {
        id
        nombres
        apellidos
        ci
        passport
        fecha_nacimiento
        sexo
        telefono
      }
      proveedorID {
        nombre
        tipo
        id
      }
      nota
    }
  }
}
`;

export const ESTADISTICAS_DASHBOARD = `
query getStatistics {
  getClientStatistics
  getFichasStatistics
  getEstudiosPorOrdenStatistics
}
`;

export const ESTADISTICAS_CURRENT_MONTH = `
query getStatisticsCurrentMonth($start: String, $end: String) {
  getFichasStatistics(start: $start, end: $end)
  getEstudiosPorOrdenStatistics(start: $start, end: $end)
}
`;

export const ESTADISTICAS_LAST_MONTH = `
query getStatisticsLastMonth($start: String, $end: String) {
  getFichasStatistics(start: $start, end: $end)
  getEstudiosPorOrdenStatistics(start: $start, end: $end)
}
`;

export const ESTUDIOS_CHART = `
query getStadisticsYearAll($start: String, $end: String) {
  getStadisticsYear(start: $start, end: $end) {
    _id {
      month
    }
    count
  }
  getFichasStatisticsYear(start: $start, end: $end) {
    _id {
      month
    }
    count
  } 
}

`;

export const COUNT_ESTUDIOS_BY_PERIOD = `
query estadisticasGeneralesOrdenes($start: String, $end: String) {
  estadisticasGeneralesOrdenes(start: $start, end: $end) {
    _id {
      estudio
      month
    }
    count
  }
  getFichasCountStatistics(start: $start, end: $end) {
    _id {
      estudio
      month
    }
    count
  }
}
`;

export const COUNT_FICHAS_BY_PERIOD = `
query estadisticasGeneralesOrdenes($start: String, $end: String) {
  estadisticasGeneralesOrdenes(start: $start, end: $end) {
    _id {
      estudio
      month
    }
    count
  }
}
`;
