import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'graphql-hooks';
import { Row, Col, Form, Input, Button, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Redirect } from "react-router";
import { Context as AuthContext } from '../context/AuthContext';
import { LOGIN } from '../mutations';

const Login = () => {
  const { setNavigation, signin, setError, state: { redirectTo } } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [login, { data, error, loading }] = useMutation(LOGIN);
  const { Title } = Typography;
  // console.log(data);

  useEffect(() => {
    setNavigation(3);
    forceUpdate({});
  }, []);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (data && data.login) {
    if (data.login.role !== "empresa") {
      alert("Su usuario no esta autorizado para ingresar a este sistema.")
    } else {
      signin(data.login)
    }
  }

  const onSubmit = (values) => {
    login({ variables: values });
  }

  return (
    <>
      <Row type="flex" style={{ backgroundColor: 'white' }}>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} >
          <img src={require('../assets/imagen.jpg')} style={{ width: '100%' }} />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} style={{ paddingLeft: '10px', paddingRight: '10px', alignSelf: 'center' }}>
          <Title level={3} style={{ marginTop: '30px', marginBottom: '20px', textAlign: 'center' }}>Bienvenido!</Title>
          <Form form={form} name="horizontal_login" layout="vertical" onFinish={() => onSubmit(form.getFieldsValue())}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'No es un correo valido.', type: 'email' }]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
                type="email"
                style={{ borderRadius: '8px' }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor ingresa tu contraseña!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Contraseña"
                style={{ borderRadius: '8px' }}
                size="large"
              />
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  style={{ width: '100%' }}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  Entrar
                </Button>
              )}
            </Form.Item>
            {error ?
              error.graphQLErrors.map((item, i) =>
                <Form.Item key={i}>
                  <Typography level={4} style={{ textAlign: 'center', color: 'red' }}>{item.message}</Typography>
                </Form.Item>
              ) : null
            }
            {/* <Form.Item>
              <NavLink to="/signup">
                <Typography level={4} style={{ textAlign: 'center', color: 'blue' }}>Crear cuenta.</Typography>
              </NavLink>
            </Form.Item> */}
            <Form.Item>
              <NavLink to="/signup-empresas">
                <Typography level={4} style={{ textAlign: 'center', color: 'blue' }}>Crear cuenta para empresas.</Typography>
              </NavLink>
            </Form.Item>
            <Form.Item>
              <NavLink to="/forgot-password">
                <Typography level={4} style={{ textAlign: 'center', color: 'blue' }}>¿Olvido su contraseña?</Typography>
              </NavLink>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 4 }} lg={{ span: 4 }} ></Col>
      </Row>
    </>


  )
}

export default Login;