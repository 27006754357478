import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportXF = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Indicación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, 'indicacion')} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Tecnica:
                <Text style={dataTextStyle}> {estudio.estudioID.metodo}  </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {getValueField(estudio.camposEstudio, 'resultado')}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Interpretación:
                {
                  getValueField(estudio.camposEstudio, 'interpretacion_abierta') !== "" ?
                    <Text style={[textStyle, dataTextStyle]}> {getValueField(estudio.camposEstudio, 'interpretacion_abierta')}</Text> : null
                }
              </Text>
              {
                getValueField(estudio.camposEstudio, "reco1") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco1", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco2") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco2", "label")} `}</Text> : null
              }
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 2 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Notas:</Text>
              <Text style={longTextStyle}> El resultado de este estudio deberá ser siempre interpretado en el contexto de datos clínicos y familiares. Puede ser beneficioso para esta persona y sus familiares un asesoramiento genético para discutir las implicancias directas e indirectas de este resultado. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Referencias:
                <Text style={[dataTextStyle]}>{`           Normal: < 45 repeticiones CGG `}</Text>
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginLeft: 70 }]}> Intermedio: 45-54 repeticiones CGG </Text>
              <Text style={[textStyle, dataTextStyle, { marginLeft: 70 }]}> re-mutado: 54-200 repeticiones CGG </Text>
              <Text style={[textStyle, dataTextStyle, { marginLeft: 70 }]}> Mutación completa: &gt; 200 repeticiones CGG </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Metodología:
                <Text style={dataTextStyle}>{` Se amplifica el fragmento de ADN que flanquea las repeticiones CGG del gen FMR1 utilizando el kit AmplideX FMR1 PCR (Asuragen) y se confirma el tamaño por electroforesis capilar en un analizador genético ABI 3500. Esta determinación permite observar también las interrupciones AGG, dentro de los repetidos CGG. `}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Limitaciones de la técnica:</Text>
              <Text style={longTextStyle}> Esta metodología se utiliza para confirmar el número de repeticiones CGG del gen FMR1. Permite precisar el estado de expansión del gen mencionado hasta 200 repeticiones. Superado este número no es posible cuantificar de forma precisa, pero si observar y reportar la presencia de una expansión completa del gen. En el presente estudio no se analizan mutaciones puntuales o deleciones a nivel del gen (1, 2) </Text>
              <Text style={longTextStyle}> 1- Monaghan, et al. ACMG Standards and Guidelines for fragile X testing: a revision to the disease-specific supplements to the Standards and Guidelines for Clinical Genetics Laboratories of the American College of Medical Genetics and Genomics. 2013. Genetics in Medicine </Text>
              <Text style={longTextStyle}> 2- Draft Best Practice Guidelines for Molecular Analysis in Fragile X Syndrome. EMQN workshop, 23-24 November 2001, Strasbourg, France </Text>
            </View>
          </View>
        </View>

        <FirmaEspecialista
          nombreEspecialista="amiloidosis"
          styleFromParent={{ marginTop: 10 }}
          isSigned={estudio.isSigned}
        />

        <View style={[dataContainerStyle, { marginTop: 10 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
        </View>
        <Footer CS />
      </Page>
  )

}



export default ReportXF;