import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import WaterMark from '../informes/WaterMark';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';

const ReportPCRCOVIDEN = ({ estudio, paciente, qrValue, language }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
    qrImageStyle
  } = stylesReport;

  const englishResult = (value) => {
    switch (value) {
      case 'NO DETECTADO':
        return "NOT DETECTED";
      case 'DETECTADO':
        return "DETECTED";
      default:
        return ""
    }
  }

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("MM/DD/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("MM/DD/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
          language={language}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>{` Molecular biology `}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Test name:
                <Text style={dataTextStyle}>{` Detection of SARS-CoV-2 (COVID19) `}</Text>
              </Text>
              <Text style={textStyle}>Technique:
                <Text style={dataTextStyle}>{` Real Time Polymerase Chain Reaction | (RT-PCR) `}</Text>
              </Text>
              <Text style={textStyle}>Sample type:
                <Text style={dataTextStyle}>{` Nasopharyngeal swab `}</Text>
              </Text>
              <Text style={textStyle}>Sampling date:
                <Text style={dataTextStyle}>
                  {
                    moment(estudio.fechaTomaDeMuestra).utc().format('HH:mm') === "00:00" ?
                        ` ${moment(estudio.fechaTomaDeMuestra).utc().format('MM/DD/YYYY')}`
                          :
                      ` ${moment(estudio.fechaTomaDeMuestra).utc().format('MM/DD/YYYY HH:mm')}`
                  }
                </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Test result:
                <Text style={dataTextStyle}>{` ${englishResult(getValueField(estudio.camposEstudio, "resultado"))} `}</Text>
              </Text>
              {
                getValueField(estudio.camposEstudio, "observacion") !== "" ?
                  <Text style={textStyle}>Remarks:
                    <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observacion")} `}</Text>
                  </Text> : null
              }
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle, { textAlign: 'center' }]}>Results Interpretation:</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>Test result</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3 }, rowRightStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>Interpretation</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 4 }]}>DETECTED</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Patient is positive for COVID-19.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 4 }]}>NOT DETECTED</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4, borderBottomWidth: 1 }, rowRightStyle]}>
              <Text style={notesTextStyle}>No viral genetic material was detected.</Text>
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ marginTop: 60 }}
          isSigned={estudio.isSigned}
        />
        <View style={[mainContainerStyle]}>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer language={language} />
      </Page>
  )
}

export default ReportPCRCOVIDEN;