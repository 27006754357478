import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import App from './pages/app';
import * as serviceWorker from './serviceWorker';
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as PruebasProvider } from './context/PruebasContext';
import instance from './api/origen-api';

const client = new GraphQLClient({
  url: 'https://origen-server.herokuapp.com/graphql',
  fetch: buildAxiosFetch(instance)
})

ReactDOM.render(
  <ClientContext.Provider value={client}>
    <BrowserRouter>
      <AuthProvider>
        <PruebasProvider>
          <App />
        </PruebasProvider>
      </AuthProvider>
    </BrowserRouter>
  </ClientContext.Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
