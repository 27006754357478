import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Routes from '../routes/routes';
import { Layout } from 'antd';
import '../styles/App.css';
import 'antd/dist/antd.css';
import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";
import { Context as AuthContext } from '../context/AuthContext';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const App = () => {
  const { Content } = Layout;
  const { state, tryLocalSignin, signout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    tryLocalSignin()
      .then((value) => {
        setLoading(false);
      })
  }, [])

  if (loading) {
    return (
      <RingLoader
        css={override}
        size={150}
        color={"#2DB9B4"}
        loading={loading}
      />
    )
  }

  return (
    <Layout>
      <Navbar {...{ state, signout }} />
      <Content>
        <Routes />
      </Content>
    </Layout>
  )
};

export default App;
