import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'graphql-hooks';
import { NavLink } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context as AuthContext } from '../context/AuthContext';
import { MailOutlined } from '@ant-design/icons';

import { Redirect } from "react-router";
import { REQUEST_FORGOT_PASSWORD } from '../mutations';

const INITIAL_VALUES = {
  email: "",
}

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [forgotPassword, { data, error, loading }] = useMutation(REQUEST_FORGOT_PASSWORD);
  const { Title, Text } = Typography;
  const { setNavigation } = useContext(AuthContext);

  useEffect(() => {
    setNavigation(3);
    forceUpdate({});
  }, [])

  const sendRequestToRecoverPassword = async (values) => {
    const { email } = values;
    if (email) {
      forgotPassword({
        variables: {
          email: email,
        }
      })
        .then(({ data, error }) => {
          if (error) {
            toast.error(error, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (data.forgotPassword) {
            form.resetFields();
            toast.success(data.forgotPassword, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
    }
  }

  return (
    <div>
      <Row type="flex" style={{ backgroundColor: 'white', padding: 10 }} >
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Inicio</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Olvido su contraseña
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} >
          <img src={require('../assets/imagen.jpg')} style={{ width: '100%' }} alt="Logo Laboratorio origen" />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} style={{ paddingLeft: '10px', paddingRight: '10px', alignSelf: 'center' }}>
          <Title level={3} style={{ marginTop: '30px', marginBottom: '20px', textAlign: 'center' }}>Recuperacion de contraseña</Title>
          <Text level={3}>Por favor ingresa la direccion de correo electronico de tu cuenta para recuperar tu contraseña</Text>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 23 }}
            layout="vertical"
            initialValues={INITIAL_VALUES}
            size='large'
          >
            <Row type="flex" style={{ backgroundColor: 'white', padding: 10 }} >
              <Col span={24}>
                <Form.Item label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Por favor ingresa tu email!', type: 'email' }]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    type="email"
                    onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={12} offset={6}>
                <Form.Item shouldUpdate={true}>
                  {() => (
                    <Button
                      style={{ width: '100%' }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => sendRequestToRecoverPassword(form.getFieldsValue())}
                      loading={loading}
                    >
                      Enviar
                    </Button>
                  )}
                </Form.Item>
                {error ?
                  error.graphQLErrors.map((item, i) =>
                    <Form.Item key={i}>
                      <Typography level={4} style={{ textAlign: 'center', color: 'red' }}>{item.message}</Typography>
                    </Form.Item>
                  ) : null
                }
                {
                  data && data.forgotPassword ?
                    <Form.Item>
                      <Typography level={2} style={{ textAlign: 'center', color: 'green' }}>Te enviaremos un email con las instrucciones para el cambio de contraseña.</Typography>
                    </Form.Item>
                    : null
                }
                <Form.Item>
                  <NavLink to="/login">
                    <Typography level={4} style={{ textAlign: 'center', color: 'blue' }}>Inicia sesion</Typography>
                  </NavLink>
                </Form.Item>
              </Col>
            </Row>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Form>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 4 }} lg={{ span: 4 }} ></Col>
      </Row>
    </div>
  )
}

export default ForgotPassword;