import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { Table, DataTableCell, TableHeader, TableBody, TableCell } from '@david.kucsai/react-pdf-table'
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const FONT_SIZE = 8;

const ReportEX = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Indicación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, 'indicacion')} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {getValueField(estudio.camposEstudio, 'resultado')}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Interpretación:</Text>
              {
                getValueField(estudio.camposEstudio, 'interpretacion_abierta') !== "" ?
                  <Text style={[textStyle, dataTextStyle]}> {getValueField(estudio.camposEstudio, 'interpretacion_abierta')}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco1") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco1", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco2") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco2", "label")} `}</Text> : null
              }
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Observaciones:</Text>
              <Text style={longTextStyle}> Variantes de significancia clínica incierta detectadas </Text>
              <Text style={longTextStyle}> Se detectaron dos variantes de significado INCIERTO: una en hemicigosis en el gen MSL3 y una en heterocigosis en el gen HECW2.</Text>
              <Text style={longTextStyle}> La variante c.571A&gt;G; p.(Ile191Val), encontrada en hemicigosis en el gen MSL3, se trata de una variante de tipo missense (cambio de sentido) que ocupa un dominio funcional importante en la proteína (MRG). La misma presenta una frecuencia cercana a cero en el banco de control gnomAD y nunca fue descrita en bancos de datos de variantes ni en la literatura. Los algoritmos de predicción in silico concuerdan que tal variante puede no causar daños a la proteína. Por lo tanto, dicha variante fue descrita como de significado INCIERTO.</Text>
              <Text style={longTextStyle}> Las variantes patogénicas/probablemente patogénicas en hemicigosis/heterocigosis en el gen MSL3 ya fueron asociadas al fenotipo del Síndrome Basilicata-Akhtar en el banco de datos OMIM. (diferente de 301032).</Text>
              <Text style={longTextStyle}> La variante c.3010G&gt;A; p.(Val1004Met), encontrada en heterocigosis en el gen HECW2 es una variante de tipo missense (cambio de sentido). La misma presenta una frecuencia cercana a cero en el banco de control gnomAD. Los algoritmos de predicción in silico concuerdan que esta variante puede causar daños en la proteína. Esta variante nunca fue descrita anteriormente en el banco de datos de variantes ni en la literatura. Por lo tanto, la misma ha sido clasificada como de significado INCIERTO.</Text>
              <Text style={longTextStyle}> Las variantes patogénicas/probablemente patogénicas en heterocigosis en el gen HECW2 ya fueron asociadas al fenotipo de trastorno del neurodesarrollo con hipotonía, convulsiones y trastornos en el lenguaje en el banco de datos OMIM (diferente de 617268).</Text>
              <Text style={longTextStyle}> Las variantes de significado incierto se refieren a variantes que en el momento del análisis no presentan evidencias científicas, ni datos suficientes para poder ser clasificadas como causantes de daño. Por lo tanto, puede existir alteración en la clasificación de dichas variantes con el tiempo, debido a la evolución del conocimiento científico. </Text>
              <Text style={longTextStyle}> En caso de existir una correlación con el cuadro clínico del paciente, se sugiere la búsqueda de la variante en los progenitores o el seguimiento de la misma en los bancos de datos o en la literatura, e incluso la solicitud de reanalizar los datos si el médico lo considera necesario. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <Text style={longTextStyle}> Datos de las variantes de significancia clínica incierta detectadas: </Text>
          <View style={[dataContainerStyle]}>
            <Table data={[
              { campo0: 'MSL3', campo1: "chrX:11780354", campo2: "NM_078629.3", campo3: "c.571A>G", campo4: "p.(lle191Val)", campo5: "rs373294859", campo6: "Significado incierto" },
              { campo0: 'HECW2', campo1: "Chr2:197143377", campo2: "NM_020760.1", campo3: "c.3010G>A", campo4: "p.(Val1004Met)", campo5: "rs569676447", campo6: "Significado incierto" }
            ]}>
              <TableHeader textAlign={"center"} fontSize={FONT_SIZE}>
                <TableCell weighting={0.4}> Gen </TableCell>
                <TableCell style={{ fontWeight: '800' }}> Coordenada genómica </TableCell>
                <TableCell style={{ fontWeight: '800' }}> Transcripto </TableCell>
                <TableCell style={{ fontWeight: '800' }}>Alteración en la secuencia de nucleótidos </TableCell>
                <TableCell style={{ fontWeight: '800' }}> Alteración en la secuencia Aminoacidica </TableCell>
                <TableCell style={{ fontWeight: '800' }}> dbSNP </TableCell>
                <TableCell style={{ fontWeight: '800' }}> Clasificación </TableCell>
              </TableHeader>
              <TableBody textAlign={"center"} fontSize={FONT_SIZE}>
                <DataTableCell weighting={0.4} getContent={(r) => r.campo0} />
                <DataTableCell getContent={(r) => r.campo1} />
                <DataTableCell getContent={(r) => r.campo2} />
                <DataTableCell getContent={(r) => r.campo3} />
                <DataTableCell getContent={(r) => r.campo4} />
                <DataTableCell getContent={(r) => r.campo5} />
                <DataTableCell getContent={(r) => r.campo6} />
              </TableBody>
            </Table>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 12 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Parámetros de calidad de la secuenciación:</Text>
              <Text style={longTextStyle}>  Número promedio de veces que se leyó cada base: 124x </Text>
              <Text style={longTextStyle}>  Número de secuencias generadas: 59.059.272 </Text>
              <Text style={longTextStyle}>  Porcentaje de bases con lectura superior a 20X: 98,56% </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 12 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Interpretación de los resultados de hallazgos secundarios</Text>
              <Text style={longTextStyle}> Siguiendo las recomendaciones de la ACMG, analizamos la presencia de las variantes patogénicas /probablemente patogénicas en los genes recomendados y que podrían no estar relacionados al cuadro clínico que indicó la realización del examen. Este estudio no identificó variantes patogénicas o probablemente patogénicas que sean responsables de otras enfermedades no asociadas al cuadro clínico presentado por el paciente. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Notas:</Text>
              <Text style={longTextStyle}> - El resultado de este estudio deberá ser siempre interpretado en el contexto de datos clínicos y familiares. </Text>
              <Text style={longTextStyle}> - Puede ser beneficioso para esta persona y sus familiares un asesoramiento genético-clínico para discutir las implicancias directas e indirectas de éste resultado. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Metodología:</Text>
              <Text style={longTextStyle}> El ADN genómico se extrae a partir de material biológico. La librería se prepara mediante el método de enriquecimiento, seguido de la captura de los objetivos con el kit xGen Exome Research Panel v2. La librería se procesa por secuenciación de nueva generación (NextSeq, Illumina). La región objetivo incluye el ADN codificante (CDS) y los sitios de corte y empalme. La cobertura vertical promedio esperada es 100x, la cobertura horizontal es mayor o igual al 95% a 10x y mayor o igual al 97,5% a 20x. El análisis bioinformático se realiza mediante la plataforma StrandOmics, basada en la versión GRCh37 del genoma humano y es capaz de detectar variantes de una única base (SNV) y pequeñas inserciones y deleciones (InDels). La selección de los genes analizados y la interpretación médica están guiadas por la orden médica y la información clínica reportada. Las variantes detectadas se filtran utilizando los siguientes criterios: (1) variantes con una frecuencia alélica poblacional &lt;1%; (2) variantes en genes causantes de enfermedades en bases de datos como OMIM; (3) variantes no sinónimos; (4) variantes que no se ubican en áreas de duplicación segmentaria (sin pseudogenes). </Text>
              <Text style={longTextStyle}> Las variantes detectadas se clasifican en Patógenicas, Probablemente Patógenicas, Benignas, Probablemente Benignas y Variantes de Significado Incierto, según los criterios del College of Medical Genetics and Genomics (ACMG). Todas las variantes patógenicas y probablemente patógenicas se describen en el informe. Las variantes de significado incierto (VUS) se informan solo cuando existe una correlación con la clínica del paciente. </Text>
              <Text style={longTextStyle}> Cuando se encuentran variantes patógenicas, éstas son confirmadas mediante secuenciación Sanger en un secuenciador automático ABI 3500 (Applied Biosystems). </Text>
              <Text style={longTextStyle}> Metodología desarrollada y validada por el laboratorio según RDC 302 del 13/10/2005, Art.5.5.5.1. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Limitaciones de la técnica:</Text>
              <Text style={longTextStyle}> Esta prueba no descarta la posibilidad de que existan otras variantes genéticas ubicadas fuera de la región cubierta por esta técnica o no detectables por la técnica utilizada. La secuenciación realizada en este examen no detecta variantes estructurales. </Text>
              <Text style={longTextStyle}> Este informe se emite de acuerdo con los conocimientos científicos actuales. La interpretación de los resultados y la clasificación de variantes pueden cambiar con el avance del conocimiento médico, o la mejora de las herramientas de análisis de datos. </Text>
              <Text style={longTextStyle}> En este sentido, es posible volver a analizar los datos del exoma a solicitud del médico responsable: el nuevo análisis puede generar costos adicionales y el laboratorio se reserva el derecho de cobrar por este nuevo análisis si el informe original se emitió hace más de 12 meses. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Examen realizado por la marca Geneone - Medicina de Precisión </Text>
              <Text style={longTextStyle}> La ausencia de variantes patogénicas no puede considerarse como un valor de referencia debido a la complejidad del análisis, y se recomienda correlacionar los resultados de este examen con el cuadro clínico y otras pruebas de laboratorio relevantes. </Text>
              <Text style={longTextStyle}> Las pruebas genéticas no son las herramientas de diagnóstico definitivas. Es importante que se tenga en cuenta que pueden existir fuentes de error (muy poco frecuentes, pero posibles). El objetivo de este examen es ayudar al médico acerca del tratamiento a realizar con el paciente, pero esta información no debe ser la única fuente de diagnóstico. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Criterios y bases de datos utilizados para la clasificación de variantes y referencias </Text>
              <Text style={longTextStyle}> Se utilizaron las siguientes bases de datos para clasificar y obtener las referencias: </Text>
              <Text style={longTextStyle}> - ClinVar – https://www.ncbi.nlm.nih.gov/clinvar/ </Text>
              <Text style={longTextStyle}> - HGMD – The Human Gene Mutation Database – http://www.hgmd.cf.ac.uk </Text>
              <Text style={longTextStyle}> - 1000Genomes Browser – https://www.ncbi.nlm.nih.gov/variation/tools/1000genomes/ </Text>
              <Text style={longTextStyle}> - gnomAD browser beta/GenomeAggregationDatabase - http://gnomad.broadinstitute.org/ </Text>
              <Text style={longTextStyle}> - NHLBI Exome Sequencing Project (ESP) - Exome Variant Server http://evs.gs.washington.edu/EVS/ </Text>
              <Text style={longTextStyle}> - ExAC Browser (Beta) | Exome Aggregation Consortium - http://exac.broadinstitute.org/ </Text>
              <Text style={longTextStyle}> - ABraOM: Brazilian Genomic Variants – http://abraom.ib.usp.br/ </Text>
              <Text style={longTextStyle}> - Online Mendelian Inheritance in Man – https://omim.org </Text>
              <Text style={longTextStyle}> - Brazilian Initiative on Precision Medicine - https://bipmed.org/ </Text>
              <Text style={longTextStyle}> - Abou Tayoun AN, et al. ClinGen Sequence Variant Interpretation Working Group (ClinGen SVI). Recommendations for interpreting the loss of function PVS1 ACMG/AMP variant criterion. Hum Mutat. 2018 Nov; 39(11):1517-1524. </Text>
              <Text style={longTextStyle}> - Richards S, et al. Standards and Guidelines for the Interpretation of Sequence Variants: A Joint Consensus Recommendation of the American. </Text>
            </View>
          </View>
        </View>

        <FirmaEspecialista
          nombreEspecialista="exoma"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
        />

        <View style={[dataContainerStyle, { marginTop: 20 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
        </View>
        <Footer CS />
      </Page>
  )

}



export default ReportEX;