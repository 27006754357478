import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'graphql-hooks';
import { NavLink } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Breadcrumb,
  Typography
} from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context as AuthContext } from '../context/AuthContext';
import { UserOutlined, LockOutlined, MailOutlined, HomeOutlined } from '@ant-design/icons';

import { Redirect } from "react-router";
import { SIGNUP_EMPRESA } from '../mutations';

const INITIAL_VALUES = {
  email: "",
  password: "",
  nombre: "",
  apellido: "",
  compania: "",
  telefono: ""
}

const Signup = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [createUserCompany, { data, error, loading }] = useMutation(SIGNUP_EMPRESA);

  const { Title } = Typography;

  const { setNavigation } = useContext(AuthContext);

  useEffect(() => {
    setNavigation(3);
    forceUpdate({});
  }, [])

  const createAccount = async (values) => {
    const { email, password, nombre, apellido, compania, telefono } = values;
    if (email && password && nombre && apellido && compania && telefono) {
      createUserCompany({ variables: { input: values } })
        .then(({ data, error }) => {
          if (error) {
            toast.error("Hubo un error al crear su cuenta.", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (data.createUserCompany) {
            form.resetFields();
            toast.success(data.createUserCompany, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
    }
  }

  return (
    <div>
      <Row type="flex" style={{ backgroundColor: 'white', padding: 10 }} >
        <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Inicio</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Crear cuenta
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} >
          <img src={require('../assets/imagen.jpg')} style={{ width: '100%' }} alt="Logo Laboratorio origen" />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} style={{ paddingLeft: '10px', paddingRight: '10px', alignSelf: 'center' }}>
          <Title level={3} style={{ marginTop: '30px', marginBottom: '20px', textAlign: 'center' }}>Bienvenido!</Title>
          <Form
            form={form}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 23 }}
            layout="vertical"
            initialValues={INITIAL_VALUES}
            size='large'
          >
            <Row type="flex" style={{ backgroundColor: 'white', padding: 10 }} >
              <Col span={24}>
                <Form.Item label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Por favor ingresa tu email!', type: 'email' }]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    type="email"
                    onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Nombre de la Empresa"
                  name="compania"
                  rules={[{ required: true, message: 'Por favor ingresa el nombre de la empresa!' }]}
                >
                  <Input
                    prefix={<HomeOutlined className="site-form-item-icon" />}
                    type="text"
                    onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Nombre del representante"
                  name="nombre"
                  rules={[{ required: true, message: 'Por favor escriba su nombre' }]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="text"
                    onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Apellido del representante"
                  name="apellido"
                  rules={[{ required: true, message: 'Por favor escriba su apellido' }]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="text"
                    onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Numero de telefono de contacto"
                  name="telefono"
                  rules={[{ required: true, message: 'Por favor escriba un numero de telefono de contacto' }]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="tel"
                    onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[{ required: true, message: 'Ingresa una contraseña.' }]}
                  hasFeedback
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    style={{ borderRadius: '8px' }}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12} offset={6}>
                <Form.Item shouldUpdate={true}>
                  {() => (
                    <Button
                      style={{ width: '100%' }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => createAccount(form.getFieldsValue())}
                      loading={loading}
                    >
                      Crear Cuenta
                    </Button>
                  )}
                </Form.Item>
                {error ?
                  error.graphQLErrors.map((item, i) =>
                    <Form.Item key={i}>
                      <Typography level={4} style={{ textAlign: 'center', color: 'red' }}>{item.message}</Typography>
                    </Form.Item>
                  ) : null
                }
                {
                  data && data.createUserCompany ?
                    <Form.Item>
                      <Typography level={2} style={{ textAlign: 'center', color: 'orange' }}>Contacte al administrador para acceder al sistema.</Typography>
                    </Form.Item>
                    : null
                }
                <Form.Item>
                  <NavLink to="/login">
                    <Typography level={4} style={{ textAlign: 'center', color: 'blue' }}>Ya tienes una cuenta? Inicia sesion.</Typography>
                  </NavLink>
                </Form.Item>
              </Col>
            </Row>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Form>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 4 }} lg={{ span: 4 }} ></Col>
      </Row>
    </div>
  )
}

export default Signup;