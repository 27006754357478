import React, { useEffect, useState } from 'react';
import { Row, Col, Tag, Button, Empty } from 'antd';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';
import RingLoader from "react-spinners/RingLoader";
import { SyncOutlined } from '@ant-design/icons';
import { css } from "@emotion/core";
import { useManualQuery } from 'graphql-hooks';
import DownloadButton from '../components/DownloadButton';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const InformesEmpresa = ({ test, query, variablesQuery, methodQuery, nombreEmpresa }) => {
  const [methodQueryExec] = useManualQuery(query);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [silentLoading, setSilentLoading] = useState(false);
  let refTab = React.createRef();

  useEffect(() => {
    setLoading(true);
    fetchInformes();
  }, [])

  const fetchInformes = () => {
    methodQueryExec({
      variables: variablesQuery
    }).then(({ data, error }) => {
      if (data[methodQuery]) {
        setResults(data[methodQuery])
      }
      if (error) {
        setResults([])
      }
      setSilentLoading(false);
      setLoading(false);
    })
  }

  const ButtonsAction = (props) => {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        <DownloadButton rowData={rowData} test={test} />
      </>
    )
  }

  const LabelResult = (props) => {
    let color = 'green';
    let labelStatus = 'DISPONIBLE';
    const rowData = props.cell._cell.row.data;
    if (!rowData.resultado) {
      color = 'orange';
      labelStatus = 'NO DISPONIBLE';
    }
    return (
      <>
        <Tag color={color}>
          {labelStatus}
        </Tag>
      </>
    )
  }

  const columnsTable = () => {
    if (nombreEmpresa === "Shell Bolivia" || nombreEmpresa === "DemoCompany") {
      return [
        { title: "Empresa", field: 'tipoFicha', headerFilter: "input", headerFilterPlaceholder: "Filtrar", },
        { title: "Fecha toma de muestra", field: 'fechaSolicitud', headerFilter: "input", headerFilterPlaceholder: "Filtrar", },
        {
          title: "Codigo",
          field: 'codigo',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
        },
        {
          title: "Nombres",
          field: 'clientID.nombres',
          cellVertAlign: 'center',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
        },
        {
          title: "Apellidos",
          field: 'clientID.apellidos',
          cellVertAlign: 'center',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
        },
        {
          title: "CI",
          field: 'clientID.ci',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
        },
        {
          title: "Resultado",
          field: 'resultado',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
        },
        {
          title: "Estatus",
          field: 'resultado',
          headerSort: false,
          formatter: reactFormatter(
            <LabelResult />
          ),
        },
        {
          title: "",
          field: "custom",
          hozAlign: "center",
          headerSort: false,
          formatter: reactFormatter(
            <ButtonsAction />
          ),
        }
      ]
    }
    return [
      { title: "Fecha toma de muestra", field: 'fechaSolicitud', headerFilter: "input", headerFilterPlaceholder: "Filtrar", },
      {
        title: "Codigo",
        field: 'codigo',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
      },
      {
        title: "Nombres",
        field: 'clientID.nombres',
        cellVertAlign: 'center',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
      },
      {
        title: "Apellidos",
        field: 'clientID.apellidos',
        cellVertAlign: 'center',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
      },
      {
        title: "CI",
        field: 'clientID.ci',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
      },
      {
        title: "Estatus",
        field: 'resultado',
        headerSort: false,
        formatter: reactFormatter(
          <LabelResult />
        ),
      },
      {
        title: "",
        field: "custom",
        hozAlign: "center",
        headerSort: false,
        formatter: reactFormatter(
          <ButtonsAction />
        ),
      }
    ]
  }

  const optionsTab = {
    reactiveData: true,
    layout: "fitColumns",
    pagination: "local",
    paginationSize: 30,
  }

  if (loading) {
    return (
      <Row type="flex" align="middle" style={{ backgroundColor: 'white' }}>
        <Col span={24} style={{ paddingTop: '20px' }}>
          <RingLoader
            css={override}
            size={150}
            color={"#2DB9B4"}
            loading={loading}
          />
        </Col>
      </Row>
    )
  }
  return (
    <Row type="flex" style={{ backgroundColor: 'white' }}>
      <Col span={24} style={{ alignContent: 'center' }}>
        <Button
          type="primary"
          shape="round"
          size='middle'
          icon={<SyncOutlined />}
          style={{ float: "right", marginLeft: 10 }}
          loading={silentLoading}
          onClick={(e) => {
            e.preventDefault();
            fetchInformes();
            setSilentLoading(true);
          }}
        >
          Actualizar
        </Button>
      </Col>
      {results.length > 0 ?
        <Col span={24}>
          <ReactTabulator
            ref={ref => (refTab = ref)}
            columns={columnsTable()}
            data={results}
            options={optionsTab}
          />
        </Col> :
        <Col span={24}>
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 350,
            }}
            description={
              <span>
                No se encontraron informes {test.nombre}.
        </span>
            }
          ></Empty>
        </Col>
      }
    </Row>
  )
}

export default InformesEmpresa;