import React from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";
import WaterMark from "../informes/WaterMark";

const ReportFH = ({ estudio, paciente }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
  } = stylesReport;

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud)
          .utc()
          .format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
        spaceMedico={true}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Indicación Clínica:
              <Text style={dataTextStyle}>
                {" "}
                {` ${getValueField(
                  estudio.camposEstudio,
                  "indicacion_clinica"
                )} `}{" "}
              </Text>
            </Text>
            <Text style={textStyle}>
              Tipo de Estudio:
              <Text style={dataTextStyle}>
                {" "}
                {` ${getValueField(
                  estudio.camposEstudio,
                  "tipo_de_estudio"
                )} `}{" "}
              </Text>
            </Text>
            <Text style={textStyle}>
              Metodología:
              <Text style={dataTextStyle}>
                {" "}
                {` ${estudio.estudioID.metodo} `}{" "}
              </Text>
            </Text>
            <View style={dividerStyle}></View>
          </View>
        </View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>Fish:</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Total Células Analizadas:
              <Text style={dataTextStyle}>{` ${getValueField(
                estudio.camposEstudio,
                "total_celulas_analizadas"
              )} `}</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Resultado:
              <Text style={dataTextStyle}>{` ${getValueField(
                estudio.camposEstudio,
                "resultado"
              )} `}</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Interpretación:
              <Text style={dataTextStyle}>{` ${getValueField(
                estudio.camposEstudio,
                "interpretacion"
              )} `}</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>Limitaciones:</Text>
            <Text style={[textStyle, dataTextStyle]}>
              {" "}
              La prueba de FISH en células linfocitarias es especifica y solo
              está diseñada para detectar anomalías genéticas en la región de
              ADN de la sonda utilizada y no así otros rearreglos genéticos en
              otros genes o cromosomas. Estas incluyen mosaicismo, anomalías
              estructurales y otras anomalías cromosómicas numéricas no
              contempladas en el estudio. Por lo tanto, se recomienda el
              análisis citogenético de rutina o cariotipo para la interpretación
              final del resultado reflejado en este estudio.
            </Text>
          </View>
        </View>
      </View>

      <View style={[dataContainerStyle, { marginTop: 70 }]}>
        <FirmaEspecialista
          nombreEspecialista="natalia"
          styleFromParent={{ flexDirection: "column", flex: 1 }}
          isSigned={estudio.isSigned}
        />
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ flexDirection: "column", flex: 1 }}
          isSigned={estudio.isSigned}
        />
      </View>
      <Footer />
    </Page>
  );
};

export default ReportFH;
