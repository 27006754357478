import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Breadcrumb, Empty } from 'antd';
import { NavLink } from 'react-router-dom';
import RingLoader from "react-spinners/RingLoader";
import { useManualQuery } from 'graphql-hooks';
import { Context as AuthContext } from '../context/AuthContext';
import { GET_PRUEBA, GET_FICHAS_MY_EMPRESA } from '../queries';
import InformesEmpresa from '../components/InformesEmpresa';
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ListaInformesMiEmpresa = (props) => {
  const { setNavigation, state: { user } } = useContext(AuthContext);
  const [getTest] = useManualQuery(GET_PRUEBA);
  const [test, setTest] = useState(null);
  const [loading, setLoading] = useState(true);
  const IDTEST = props.match.params.id;

  useEffect(() => {
    setLoading(true);
    getPrueba();
  }, [IDTEST])

  const getPrueba = () => {
    getTest({ variables: { id: IDTEST } }).then(({ data }) => {
      if (data.getTest) {
        setTest(data.getTest);
        if (data.getTest.codigoPrueba === 'C')
          setNavigation(5);
        else if (data.getTest.codigoPrueba === 'e') {
          setNavigation(6);
        } else {
          setNavigation(7);
        }
      }
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <Row type="flex" align="middle" style={{ backgroundColor: 'white' }}>
        <Col span={24} style={{ paddingTop: '20px' }}>
          <RingLoader
            css={override}
            size={150}
            color={"#2DB9B4"}
            loading={loading}
          />
        </Col>
      </Row>
    )
  }



  return (
    <Row type="flex" style={{ backgroundColor: 'white' }}>
      <Col span={24} style={{ padding: '10px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">Inicio</NavLink>
          </Breadcrumb.Item>
          {
            test ?
              <Breadcrumb.Item>
                Informes {test.nombre} de {user.compania}
              </Breadcrumb.Item> : null
          }
        </Breadcrumb>
      </Col>
      {
        user.blockByLackOfPayment ?
          <Col span={24} style={{ padding: '10px' }}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 350,
              }}
              description={
                <span>
                  Por favor confirmar su pago para acceder a los resultados.
                </span>
              }
            ></Empty>
          </Col> :
          <Col span={24} style={{ padding: '10px' }}>
            {test && !loading ?
              <InformesEmpresa
                test={test}
                query={GET_FICHAS_MY_EMPRESA}
                variablesQuery={{ testId: test.id }}
                methodQuery="getFichasMyEmpresa"
                nombreEmpresa={user.compania}
              /> : null
            }
          </Col>
      }
    </Row>
  )
}

export default ListaInformesMiEmpresa;