import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Collapse, Typography, Breadcrumb, Form, DatePicker, Select, Button, Empty } from 'antd';
import { useManualQuery } from 'graphql-hooks';
import { NavLink } from 'react-router-dom';
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/core";
import { Redirect } from "react-router";
import { GET_PRECIOS_POR_PROVEEDOR, GET_FICHAS_MY_EMPRESA, GET_PRECIOS_POR_PROVEEDORES, GET_ORDENES_MY_EMPRESA } from '../queries';
import { Context as AuthContext } from '../context/AuthContext';
import InformesEmpresaNuevo from '../components/InformesEmpresasNuevo';
import moment from 'moment';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const styleLayout = css`
  padding: 10
`;

const INITIAL_VALUES = {
  informe: "all",
  start: moment().subtract(1, 'months'),
  end: moment()
}

const InformesEmpresa = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const { setNavigation, state: { user } } = useContext(AuthContext);
  const [getPreciosPorProveedor] = useManualQuery(GET_PRECIOS_POR_PROVEEDOR);
  const [getPreciosPorProveedores] = useManualQuery(GET_PRECIOS_POR_PROVEEDORES);
  const [getOrdenesMyEmpresa] = useManualQuery(GET_ORDENES_MY_EMPRESA);
  const [getFichasMyEmpresa] = useManualQuery(GET_FICHAS_MY_EMPRESA);

  const { Title } = Typography;
  const [rows, setRows] = useState([]);
  const [estudios, setEstudios] = useState([]);
  const [redirectTo, setRedirectTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(true);
  let refTab = React.createRef();
  const { Option } = Select;

  useEffect(() => {
    setNavigation(1);
    fetchEstudiosDisponibles();
    fetchEstudios();
  }, []);


  const fetchFichas = (idEstudio, array, query) => {

    getFichasMyEmpresa({
      variables: {
        testId: idEstudio ? idEstudio : "",
        query
      }
    }).then(({ data, error }) => {

      if (error) {
        console.log(error)
        setBusy(false)
        return;
      }
      if (data.getFichasMyEmpresa) {
        setRows([...array, ...data.getFichasMyEmpresa]);
        setBusy(false)
      }
    })
  }


  const fetchEstudiosDisponibles = () => {
    const proveedoresIDS = user.listProveedores.map((item) => {
      return item.id
    });

    getPreciosPorProveedores({
      variables: {
        id: proveedoresIDS
      }
    }).then(({ data, error }) => {
      if (data && data.getPreciosPorProveedores) {
        var arrayEstudios = [];

        const nuevaLista = data.getPreciosPorProveedores.filter((item) => {
          if (!arrayEstudios.includes(item.estudioID.id)) {
            arrayEstudios.push(item.estudioID.id)
            return item;
          }
        })
        setEstudios(nuevaLista)
      }
    })
  }


  const fetchEstudios = (estudioID = "all", start = moment().subtract(1, 'months'), end = moment(), fichasID = null) => {
    setBusy(true);
    getOrdenesMyEmpresa({
      variables: {
        query: {
          fecha_start: start.format("YYYY-MM-DD"),
          fecha_fin: end.format("YYYY-MM-DD"),
          estudioID: estudioID
        }
      },
      useCache: false
    }).then(({ data, error }) => {
      if (error) {
        setBusy(false)
        console.log(error)
        return;
      }

      if (data.getOrdenesMyEmpresa) {
        if (estudioID !== 'all' && !fichasID) {
          setRows(data.getOrdenesMyEmpresa);
          setBusy(false)
          return;
        }
        fetchFichas(fichasID, data.getOrdenesMyEmpresa, {
          fecha_start: start.format("YYYY-MM-DD"),
          fecha_fin: end.format("YYYY-MM-DD"),
        });
      }
    })
  }

  const submitForm = (values) => {
    const { informe, end, start } = values

    if (informe && end && start) {
      // Fichas de PCR Covid-19
      if (values.informe === "604d353635df190004250e5b") {
        fetchEstudios(informe, start, end, "5f19c26822e6db619738813d")
        return;
      }

      // Fichas de ELISA Covid-19
      if (values.informe === "604e2602cc5c345ec442f32e") {
        fetchEstudios(informe, start, end, "5f1b35777cb6272305854ddb")
        return;
      }

      // Fichas de PCR Saliva Covid-19
      if (values.informe === "60901bc42cd46b000451e5f6") {
        fetchEstudios(informe, start, end, "60647bc51f5e76393874fc5e")
        return;
      }

      // Fichas de Antigeno
      if (values.informe === "60b95bd9092ae30004224ddd") {
        fetchEstudios(informe, start, end, "60b7908efff81d7b9f1ec537")
        return;
      }

      // Fichas de Anticuerpos Neutralizantes ACE2-RBD
      if (values.informe === "60b959f6092ae30004224ddb") {
        fetchEstudios(informe, start, end, "60b61774424baa2067ba3853")
        return;
      }

      // Fichas de Anticuerpos Neutralizantes ACE2-RBD (TOTAL)
      if (values.informe === "6136babfce956300042d7537") {
        fetchEstudios(informe, start, end, "612edfc79fbf1f7a80834d97")
        return;
      }

      fetchEstudios(informe, start, end, null);
    }
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (loading) {
    return (
      <Row type="flex" align="middle" style={{ backgroundColor: 'white', padding: '20px' }}>
        <Col span={24} style={styleLayout}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Inicio</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24} style={styleLayout}>
          <RingLoader
            css={override}
            size={150}
            color={"#2DB9B4"}
            loading={loading}
          />
        </Col>
      </Row>
    )
  }

  if (user.blockByLackOfPayment) {
    return (
      <Row type="flex" align="middle" style={{ backgroundColor: 'white', padding: '20px' }}>
        <Col span={24} style={styleLayout}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Inicio</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={24} style={{ padding: '10px' }}>
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 350,
            }}
            description={
              <span>
                Por favor confirmar su pago para acceder a los resultados.<br />
                Ponerse en contacto con la Lic. Alba Colmenares al numero <strong>78436728</strong>
              </span>
            }
          ></Empty>
        </Col>
      </Row>
    )
  }

  return (
    <Row gutter={16} type="flex" style={{ backgroundColor: 'white', padding: '20px' }}>
      <Col span={24} style={{ padding: '10px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">Inicio</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Descargar informes
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col span={24}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 23 }}
          layout="vertical"
          size='large'
          initialValues={INITIAL_VALUES}
        >
          <Row type="flex" style={{ backgroundColor: 'white' }} >
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item
                label="Seleccione el tipo de estudio"
                rules={[{ required: true, message: 'Este campo es requerido' }]}
                name="informe"
              >
                <Select style={{ width: '100%' }}>
                  <Option key="all-.012" value="all">TODOS</Option>
                  {
                    estudios.map((item) => {
                      return (
                        <Option key={`i-${item.estudioID.id}`} value={item.estudioID.id}>{item.estudioID.nombre}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item
                label="Fecha inicio"
                name="start"
                rules={[{ required: true, message: 'Este campo es requerido' }]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  rules={[{ required: true, message: 'Por favor seleccione una fecha!' }]}
                  style={{ width: "100%" }}
                  disabledDate={d => !d || d.isAfter(moment())}
                  placeholder="Seleccione una fecha"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Form.Item
                label="Fecha fin"
                name="end"
                rules={[{ required: true, message: 'Este campo es requerido' }]}
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  rules={[{ required: true, message: 'Por favor seleccione una fecha!' }]}
                  style={{ width: "100%" }}
                  disabledDate={d => !d || d.isAfter(moment())}
                  placeholder="Seleccione una fecha"
                />
              </Form.Item>
            </Col>
            <Col span={12} offset={6}>
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => submitForm(form.getFieldsValue())}
                    loading={busy}
                  >
                    {busy ? 'Consultando' : 'Consultar'}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {
              busy ?
                <Col span={24} style={styleLayout}>
                  <RingLoader
                    css={override}
                    size={150}
                    color={"#2DB9B4"}
                    loading={busy}
                  />
                </Col> :
                <>
                  {
                    (rows.length > 0 && !busy) ?
                      <Col span={24}>
                        <InformesEmpresaNuevo
                          dataInformes={rows}
                          nombreEmpresa={user.pNombre}
                        />
                      </Col>
                      : <Col span={24}>
                        <Empty
                          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                          imageStyle={{
                            height: 350,
                          }}
                          description={
                            <span>
                              No se encontraron informes con los parametros consultados.
                            </span>
                          }
                        ></Empty>
                      </Col>
                  }
                </>
            }
          </Row>
        </Form>
      </Col>
    </Row>

  )
}

export default InformesEmpresa;