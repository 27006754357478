import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Col, Row } from 'antd';
import { MenuOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;

const Navbar = ({ state, signout }) => {
  const { Header } = Layout;

  return (
    <Header>
      <Row style={{ alignContent: 'space between' }}>
        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 0, }} lg={{ span: 0 }}>
          <NavLink to="/">
            <div className="logo" />
          </NavLink>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 6, }} lg={{ span: 6 }}>
          <NavLink to="/" style={{ color: 'white' }}>
            Laboratorio Origen
            <div className="logo" />
          </NavLink>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 18, }} lg={{ span: 18 }}>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ float: 'right' }}
            selectedKeys={[`${state.nav}`]}
          >
            <Menu.Item key="1">
              <NavLink to="/">Inicio</NavLink>
            </Menu.Item>
            {/* {
              state.token && state.user.role === "empresa" ?
                <SubMenu title="Informes">
                  <Menu.Item key="5">
                    <NavLink to="/informes-empresa/5f19c26822e6db619738813d">Informes PCR COVID-19</NavLink>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <NavLink to="/informes-empresa/5f1b35777cb6272305854ddb">Informes ELISA</NavLink>
                  </Menu.Item>
                </SubMenu>
                : null
            } */}
            {
              state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === "default" || state.user.role === "doc") ?
                <SubMenu title="Pruebas">
                  {
                    state.user.role === 'lab' || state.user.role === 'admin' || state.user.role === 'doc' ?
                      <Menu.Item key="4">
                        <NavLink to="/nueva">Nueva</NavLink>
                      </Menu.Item> : null
                  }
                  <Menu.Item key="5">
                    <NavLink to="/informes/5f19c26822e6db619738813d">PCR COVID-19</NavLink>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <NavLink to="/informes/5f1b35777cb6272305854ddb">ELISA</NavLink>
                  </Menu.Item>
                </SubMenu> : null
            }
            {
              state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === 'doc') ?
                <SubMenu title="Pendientes">
                  <Menu.Item key="13">
                    <NavLink to="/pendientes/5f19c26822e6db619738813d">PCR COVID-19</NavLink>
                  </Menu.Item>
                  <Menu.Item key="14">
                    <NavLink to="/pendientes/5f1b35777cb6272305854ddb">ELISA</NavLink>
                  </Menu.Item>
                </SubMenu> : null
            }
            {
              state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === "default" || state.user.role === 'doc') ?
                <SubMenu title="Pacientes">
                  <Menu.Item key="7">
                    <NavLink to="/pacientes/nuevo">Nuevo</NavLink>
                  </Menu.Item>
                  <Menu.Item key="8">
                    <NavLink to="/pacientes">Lista de Pacientes</NavLink>
                  </Menu.Item>
                </SubMenu> : null
            }
            {
              state.token && state.user.role === "admin" ?
                <SubMenu title="Empresas">
                  <Menu.Item key="9">
                    <NavLink to="/empresas">Lista de Empresas</NavLink>
                  </Menu.Item>
                </SubMenu> : null
            }
            {
              state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === "default") ?
                <SubMenu title="Conciliacion">
                  <Menu.Item key="10">
                    <NavLink to="/conciliacion/5f19c26822e6db619738813d">PCR COVID-19</NavLink>
                  </Menu.Item>
                  <Menu.Item key="11">
                    <NavLink to="/conciliacion/5f1b35777cb6272305854ddb">ELISA</NavLink>
                  </Menu.Item>
                </SubMenu> : null
            }

            {
              state.token && state.user.role === "admin" ?
                <SubMenu title="Usuarios sistema">
                  <Menu.Item key="12">
                    <NavLink to="/usuarios">Lista de Usuarios</NavLink>
                  </Menu.Item>
                </SubMenu> : null
            }
            {
              state.token && state.user.role === "admin" ?
                <Menu.Item key="99">
                  <NavLink to="/resultados">Registrar Fichas</NavLink>
                </Menu.Item> : null
            }
            {
              state.token && state.user.role === "admin" ?
                <Menu.Item key="98">
                  <NavLink to="/resultados2">Registrar Clientes</NavLink>
                </Menu.Item> : null
            }
            {
              state.token ?
                <SubMenu icon={<UserOutlined />}>
                  <Menu.Item
                    key="7"
                    icon={<LogoutOutlined />}
                    onClick={() => signout()}
                  >
                    Salir
                  </Menu.Item>
                </SubMenu> :
                <Menu.Item key="3">
                  <NavLink to="/login">Login</NavLink>
                </Menu.Item>
            }
          </Menu>
        </Col>
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 0, }} lg={{ span: 0 }}>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ float: 'right' }}
            selectedKeys={[`${state.nav}`]}
          >
            <SubMenu icon={<MenuOutlined />}>
              <Menu.Item key="1">
                <NavLink to="/">Home</NavLink>
              </Menu.Item>
              {/* {
                state.token && state.user.role === "empresa" ?
                  <SubMenu title="Informes">
                    <Menu.Item key="5">
                      <NavLink to="/informes-empresa/5f19c26822e6db619738813d">Informes PCR COVID-19</NavLink>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <NavLink to="/informes-empresa/5f1b35777cb6272305854ddb">Informes ELISA</NavLink>
                    </Menu.Item>
                  </SubMenu>
                  : null
              } */}
              {
                state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === "default" || state.user.role === 'doc') ?
                  <SubMenu title="Pruebas">
                    {
                      state.user.role === 'lab' || state.user.role === 'admin' || state.user.role === 'doc' ?
                        <Menu.Item key="4">
                          <NavLink to="/nueva">Nueva</NavLink>
                        </Menu.Item> : null
                    }
                    <Menu.Item key="5">
                      <NavLink to="/informes/5f19c26822e6db619738813d">PCR COVID-19</NavLink>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <NavLink to="/informes/5f1b35777cb6272305854ddb">ELISA</NavLink>
                    </Menu.Item>
                  </SubMenu> : null
              }
              {
                state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === 'doc') ?
                  <SubMenu title="Pendientes">
                    <Menu.Item key="13">
                      <NavLink to="/pendientes/5f19c26822e6db619738813d">PCR COVID-19</NavLink>
                    </Menu.Item>
                    <Menu.Item key="14">
                      <NavLink to="/pendientes/5f1b35777cb6272305854ddb">ELISA</NavLink>
                    </Menu.Item>
                  </SubMenu> : null
              }
              {
                state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === "default" || state.user.role === 'doc') ?
                  <SubMenu title="Pacientes">
                    <Menu.Item key="7">
                      <NavLink to="/pacientes/nuevo">Nuevo</NavLink>
                    </Menu.Item>
                    <Menu.Item key="8">
                      <NavLink to="/pacientes">Lista de Pacientes</NavLink>
                    </Menu.Item>
                  </SubMenu> : null
              }
              {
                state.token && state.user.role === "admin" ?
                  <SubMenu title="Empresas">
                    <Menu.Item key="9">
                      <NavLink to="/empresas">Lista de Empresas</NavLink>
                    </Menu.Item>
                  </SubMenu> : null
              }
              {
                state.token && (state.user.role === "admin" || state.user.role === "lab" || state.user.role === "default") ?
                  <SubMenu title="Conciliacion">
                    <Menu.Item key="10">
                      <NavLink to="/conciliacion/5f19c26822e6db619738813d">PCR COVID-19</NavLink>
                    </Menu.Item>
                    <Menu.Item key="11">
                      <NavLink to="/conciliacion/5f1b35777cb6272305854ddb">ELISA</NavLink>
                    </Menu.Item>
                  </SubMenu> : null
              }

              {
                state.token && state.user.role === "admin" ?
                  <SubMenu title="Usuarios sistema">
                    <Menu.Item key="12">
                      <NavLink to="/usuarios">Lista de Usuarios</NavLink>
                    </Menu.Item>
                  </SubMenu> : null
              }
              {/* {
                state.token ?
                  <Menu.Item key="99">
                    <NavLink to="/resultados">Registrar Fichas</NavLink>
                  </Menu.Item> : null
              }
              {
                state.token ?
                  <Menu.Item key="98">
                    <NavLink to="/resultados2">Registrar Clientes</NavLink>
                  </Menu.Item> : null
              } */}
              {
                state.token ?
                  <SubMenu icon={<UserOutlined />}>
                    <Menu.Item
                      key="7"
                      icon={<LogoutOutlined />}
                      onClick={() => signout()}
                    >
                      Salir
                  </Menu.Item>
                  </SubMenu> :
                  <Menu.Item key="3">
                    <NavLink to="/login">Login</NavLink>
                  </Menu.Item>
              }
              {/* {
                state.token ?
                  <SubMenu title="PCR COVID-19">
                    <Menu.ItemGroup title="Pacientes">
                      <Menu.Item key="4">
                        <NavLink to="/new">Nuevo</NavLink>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <NavLink to="/dash">Informes</NavLink>
                      </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup title="Resultados">
                      <Menu.Item key="5">
                        <NavLink to="/set-result">Pendientes</NavLink>
                      </Menu.Item>
                      <Menu.Item key="6">
                        <NavLink to="/edit-result">Editar Resultados</NavLink>
                      </Menu.Item>
                    </Menu.ItemGroup>
                  </SubMenu>
                  :
                  null
              }
              {
                state.token ?
                  <SubMenu icon={<UserOutlined />}>
                    <Menu.Item
                      key="7"
                      icon={<LogoutOutlined />}
                      onClick={() => signout()}
                    >
                      Salir
                  </Menu.Item>
                  </SubMenu> :
                  <Menu.Item key="3">
                    <NavLink to="/login">Login</NavLink>
                  </Menu.Item>
              } */}
            </SubMenu>
          </Menu>
        </Col>
      </Row>
    </Header>
  );
}

export default Navbar;