import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../utils/PrivateRoute';
import PublicRoute from '../utils/PublicRoute';
import Home from '../pages/Home';
import Login from '../pages/Login';
import SignupEmpresas from '../pages/SignupEmpresas';
import ForgotPassword from '../pages/ForgotPassword'
import PasswordReset from '../pages/PasswordReset'
import ListaInformesMiEmpresa from '../pages/ListaInformesMiEmpresa';
import InformesEmpresas from '../pages/InformesEmpresa';


const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/passwordReset" component={PasswordReset} />
    <PublicRoute path="/login" component={Login} />
    {/* <PrivateRoute path="/informes-empresa/:id" component={ListaInformesMiEmpresa} /> */}
    <PrivateRoute path="/informes" component={InformesEmpresas} />
    <PublicRoute path="/signup-empresas" component={SignupEmpresas} />
    <PublicRoute path="/forgot-password" component={ForgotPassword} />



    <Route path="*" component={Home} />
  </Switch>
);

export default Routes;