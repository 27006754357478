import React from 'react';
import jwt from 'jsonwebtoken';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ReportPRC from './ReportPCR';
import ReportElisa from './ReportElisa';
import ReportN from './ReportN';
import ReportA from './ReportA';
import ReportO from './ReportO';

import QRCode from 'qrcode.react';
const SECRET_HASH = process.env.REACT_APP_SECRET_HASH;

const selectCorrectReport = (item, qr, codigoPrueba) => {

  if (codigoPrueba === 'C' || codigoPrueba === 'CS') {
    return <ReportPRC item={item} qrValue={qr} />
  }

  if (codigoPrueba === 'E') {
    return <ReportElisa item={item} qrValue={qr} />
  }

  if (codigoPrueba === 'N') {
    return <ReportN item={item} />
  }

  if (codigoPrueba === 'A') {
    return <ReportA item={item} qrValue={qr} />
  }

  if (codigoPrueba === 'O') {
    return <ReportO item={item} qrValue={qr}  />
  }
}

const generateLetterPdf = async (item, codigoPrueba) => {
  const { _id } = item;
  const qrCodeCanvas = document.getElementById(`canvas-${_id}`);
  const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);

  const blob = await pdf(selectCorrectReport(item, qrCodeDataUri, codigoPrueba)).toBlob();
  const name = item.clientID.nombres ? item.clientID.nombres : "";
  const familyName = item.clientID.apellidos ? item.clientID.apellidos : "";
  saveAs(blob, `${item.codigo}-${name} ${familyName}.pdf`);
};

const getDisableFlag = (codigoPrueba, resultado) => {

  if (!resultado) {
    return true;
  }

  // PCR
  if (codigoPrueba === 'C' || codigoPrueba === 'CS') {
    const RESULT = resultado.trim().toUpperCase();
    if (RESULT !== "POSITIVO" && RESULT !== "POSITIVO BAJO" && RESULT !== "NEGATIVO" && RESULT !== "NO DETECTADO" && RESULT !== "DETECTADO") {
      return true;
    }
    return false;
  }
  // Elisa
  if (codigoPrueba === 'E' || codigoPrueba === 'N' || codigoPrueba === 'O') {
    return false;
  }

  if (codigoPrueba === 'A') {
    const RESULT = resultado.trim().toUpperCase();
    if (RESULT !== "DETECTADO" && RESULT !== "NO DETECTADO") {
      return true;
    }
    return false;
  }
}

const DownloadButton = ({ rowData, test }) => {
  // const rowData = cell._cell.row.data;
  let disabled = getDisableFlag(test.codigoPrueba, rowData.resultado);
  const reportIDHash = jwt.sign({ reportId: rowData._id }, SECRET_HASH);
  const verifiedReportURL = `https://pacientes.origen.com.bo/verify?key=${reportIDHash}`;

  return (
    <>
      <Button
        type={disabled ? "text" : "primary"}
        shape="round"
        icon={<DownloadOutlined />}
        size='middle'
        disabled={disabled}
        onClick={() => generateLetterPdf(rowData, test.codigoPrueba)}
      ></Button>
      <div style={{ display: 'none' }}>
        <QRCode id={`canvas-${rowData._id}`} value={verifiedReportURL} size={128} />
      </div>
    </>
  )
}

export default DownloadButton