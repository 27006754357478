import moment from 'moment';

export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('tokenOrigen') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('tokenOrigen');
  sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem('tokenOrigen', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}

export const getEdad = (birthdate, age) => {
  if (birthdate) {
    const fecha_n = moment(birthdate, "DD/MM/YYYY");
    if (fecha_n) {
      let edad = `${moment().diff(fecha_n, 'years')}`;
      if (edad && edad > 0) {
        return edad > 1? `${edad} años` : `${edad} año`;
      } else {
        let edad = `${moment().diff(fecha_n, 'months')}`;
        if (edad && edad > 0) {
          return edad > 1? `${edad} meses` : `${edad} mes`;
        } else {
          let edad = `${moment().diff(fecha_n, 'days')}`;
          if (edad && edad > 0) {
            return edad > 1? `${edad} días` : `${edad} día`;
          }
        }
      }
    }
  }

  const edad = getNumber(age);
  if (Number(edad)) {
    return `${edad} años`;
  }
  return ""
}

export const getNumber = (value) => {
  let ci = value ? value.trim().replace(/\D+$/g, "") : null;
  if (ci) {
    ci = "";
    const numbers = value.match(/\d+/g).map(Number);
    for (let i = 0; i < numbers.length; i++) {
      ci = `${ci}${numbers[i]}`
    }
  }

  return Number(ci);
}

export const getResultElisa = (muestra, cutoff, date) => {
  let value = (Number(muestra) * 10) / cutoff;
  if (date.isSameOrAfter('2020-11-24') && date.isBefore('2020-12-04')) {
    value = (Number(muestra)) / cutoff;
  }
  return value.toFixed(1);
}

export const getResultLabelElisa = (valorMuesta, factorMultiplicador) => {
  const reference1 = 0.9 * Number(factorMultiplicador);
  const reference2 = 1.1 * Number(factorMultiplicador);

  if (valorMuesta > reference2) return 'REACTIVO';
  if (valorMuesta < reference1) return 'NO REACTIVO';

  return 'INDETERMINADO';
}

export const getResultElisaComplete = (campos, testID) => {
  const testValue = Number((Number(getValueField(campos, `valor_${testID}`)) * Number(getValueField(campos, "factor_multiplicador"))) / Number(getValueField(campos, `cutoff_${testID}`))).toFixed(1);
  const unidad = getValueField(campos, `resultado_${testID}`, 'unidad');
  const label = getResultLabelElisa(testValue, Number(getValueField(campos, "factor_multiplicador")))

  return `${testValue} ${unidad} ${label}`
}

export const getValueField = (campos, nombre, key = 'valor') => {
  const field = campos.filter((c) => {
    if (c.nombre === nombre) return c;
  })

  if (!field[0]) {
    console.error(`Campo buscado: ${nombre}, key: ${key}`)
    return "";
  }

  return field[0][key];
}

export const getEdadParto = (birthdate, fechaParto) => {
  if (birthdate) {
    const fecha_n = moment(birthdate, "DD/MM/YYYY");
    if (fecha_n) {
      let edad = `${moment(fechaParto, "DD/MM/YYYY").diff(fecha_n, 'years')}`;
      if (edad && edad > 0) {
        return `${edad} años`;
      } else {
        let edad = `${moment(fechaParto, "DD/MM/YYYY").diff(fecha_n, 'months')}`;
        if (edad && edad > 0) {
          return `${edad} meses`;
        }
      }
    }
  }

  return "";
}

export const calculateResultAN = (muestra, cutoff, date) => {
  let value = (Number(cutoff)) / Number(muestra);
  return value.toFixed(1);
}

export const getANResult = (value) => {
  if (value < 1) return 'NEGATIVO';
  if (value >= 1 && value < 5) return 'BAJO POSITIVO';
  if (value >= 5 && value < 10) return 'POSITIVO MEDIO';
  if (value >= 10) return 'POSITIVO ALTO';
}

export const calculateResultO = (muestra, cutoff, date) => {
  let value = (1 - ((Number(muestra)) / Number(cutoff) )) * 100;
  return value.toFixed(2);
}

export const getOResult = (value) => {
  if (value < 30) return 'NEGATIVO';
  if (value >= 30 && value <= 35) return 'INDETERMINADO';
  if (value > 35 ) return 'POSITIVO';
}

export const getAge = (birthdate, age) => {
  if (birthdate) {
    const fecha_n = moment(birthdate, "DD/MM/YYYY");
    if (fecha_n) {
      let edad = `${moment().diff(fecha_n, 'years')}`;
      if (edad && edad > 0) {
        return edad > 1? `${edad}` : `${edad}`;
      } else {
        let edad = `${moment().diff(fecha_n, 'months')}`;
        if (edad && edad > 0) {
          return edad > 1? `${edad} months` : `${edad} month`;
        } else {
          let edad = `${moment().diff(fecha_n, 'days')}`;
          if (edad && edad > 0) {
            return edad > 1? `${edad} days` : `${edad} day`;
          }
        }
      }
    }
  }

  const edad = getNumber(age);
  if (Number(edad)) {
    return `${edad} años`;
  }
  return ""
}