import React, { useContext, useEffect } from 'react';
import { Row, Col, Collapse, Typography } from 'antd';
import { useQuery } from 'graphql-hooks';
import { NavLink } from 'react-router-dom';
import { Context as AuthContext } from '../context/AuthContext';
import { Redirect } from "react-router";
import { GET_PRUEBAS } from '../queries';

const Home = () => {
  const { setNavigation, state: { redirectTo, token, user } } = useContext(AuthContext);
  const { Panel } = Collapse;
  const { error, data } = useQuery(GET_PRUEBAS);
  const { Title } = Typography;
  useEffect(() => {
    setNavigation(1);
  }, []);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Row gutter={16} type="flex" style={{ backgroundColor: 'white', padding: '20px' }}>
      {token ?
        <Col span={24}>
          <Title level={4}>Bienvenido {user.nombre} {user.apellido}</Title>
          <Collapse key="1">
            {user.role === "admin" || user.role === "lab" || user.role === "default" || user.role === "doc" ?
              <Panel header="Pruebas" key="1">
                {
                  user.role === 'lab' || user.role === 'admin' || user.role === "doc" ?
                    <p>
                      <NavLink to="/nueva">Agregar nueva</NavLink>
                    </p> : null
                }
                {
                  data && data.getTests ?
                    data.getTests.map((prueba, i) => {
                      const { id, nombre } = prueba;
                      return (
                        <p key={id}>
                          <NavLink to={`/informes/${id}`}>Informes {nombre}</NavLink>
                        </p>
                      )
                    }) : null
                }
              </Panel> : null
            }
            {user.role === "empresa" ?
              <Panel header="Consultar Informes" key="1">
                <p key={`myID`}>
                  <NavLink to={`/informes`}>Consultar</NavLink>
                </p>
                {/* {
                  data && data.getTests ?
                    data.getTests.map((prueba, i) => {
                      const { id, nombre } = prueba;
                      return (
                        <p key={id}>
                          <NavLink to={`/informes-empresa/${id}`}>Informes {nombre}</NavLink>
                        </p>
                      )
                    }) : null
                } */}
              </Panel> : null
            }
            {
              user.role === "admin" || user.role === "lab" || user.role === "doc" ?
                <Panel header="Pendientes por Resultado" key="2">
                  {
                    data && data.getTests ?
                      data.getTests.map((prueba, i) => {
                        const { id, nombre } = prueba;
                        return (
                          <p key={id}>
                            <NavLink to={`/pendientes/${id}`}>{nombre}</NavLink>
                          </p>
                        )
                      }) : null
                  }
                </Panel> : null
            }
            {user.role === "admin" || user.role === "lab" || user.role === "default" || user.role === "doc" ?
              <Panel header="Pacientes" key="3">
                <p>
                  <NavLink to="/pacientes/nuevo">Agregar nuevo</NavLink>
                </p>
                <p>
                  <NavLink to="/pacientes">Lista de Pacientes</NavLink>
                </p>
              </Panel> : null
            }

            {
              user.role === "admin" ?
                <Panel header="Empresas" key="4">
                  <p>
                    <NavLink to="/empresas">Lista Empresas</NavLink>
                  </p>
                </Panel> : null
            }
            {
              user.role === "admin" || user.role === "lab" || user.role === "default" ?
                <Panel header="Conciliacion" key="5">
                  {
                    data && data.getTests ?
                      data.getTests.map((prueba, i) => {
                        const { id, nombre } = prueba;
                        return (
                          <p key={id}>
                            <NavLink to={`/conciliacion/${id}`}>Conciliacion {nombre}</NavLink>
                          </p>
                        )
                      }) : null
                  }
                </Panel> : null
            }
            {
              user.role === "admin" ?
                <Panel header="Usuarios sistema" key="6">
                  <p >
                    <NavLink to="/usuarios">Lista de Usuarios</NavLink>
                  </p>
                </Panel> : null
            }
          </Collapse>
        </Col> :
        <Col span={24} style={{ padding: '20px' }}>
          <Typography>Inicia sesion para tener acceso al sistema.</Typography>
        </Col>
      }
    </Row>
  )
}

export default Home;