import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportVPH = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Prueba:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.nombre} `}</Text>
              </Text>
              <Text style={[textStyle, { marginLeft: 31 }]}>
                <Text style={dataTextStyle}>{`${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Técnica:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.metodo} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado")} `}</Text>
              </Text>
              {
                getValueField(estudio.camposEstudio, "resultado") === "Positivo" ?
                  <View>
                    <Text style={textStyle}>Subtipo:
                      <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "subtipo")} `}</Text>
                    </Text>
                    <Text style={textStyle}>Cantidad:
                      <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "cantidad")} ${getValueField(estudio.camposEstudio, "cantidad", "unidad")} `}</Text>
                    </Text>
                  </View>
                  : null
              }
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle, { textAlign: 'center' }]}>Interpretaci&oacute;n</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>Resultado</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3 }, rowRightStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>Interpretación</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>{` < 3`}</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Baja significativa Clínica.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>{` 3 - 5`}</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Clínicamente valioso. La displasia no puede ser excluida; riesgo de displasia.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 4 }]}>{` > 5 `}</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4, borderBottomWidth: 1 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Clínicamente valioso, de alto valor. La displasia es altamente sugestiva.</Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Sensibilidad:
                <Text style={textStyle}>{` ${getValueField(estudio.camposEstudio, "sensibilidad")} ${getValueField(estudio.camposEstudio, "sensibilidad", "unidad")} `}</Text>
              </Text>
              {
                getValueField(estudio.camposEstudio, "observaciones") !== "" ?
                  <Text style={textStyle}>Observaciones:
                    <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text>
                  </Text>
                  : null
              }
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
        />
        <Footer />
      </Page>
  )

}

export default ReportVPH;