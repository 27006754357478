export const LOGIN = `
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      token
      email
      nombre
      apellido
      role
      compania
      proveedorID {
        id
        nombre
      }
    }
  }
`;

export const SIGNUP = `
  mutation createUser($input: UserInput) {
    createUser(input: $input)
  }
`;

export const SIGNUP_EMPRESA = `
  mutation createUserCompany($input: UserCompanyInput) {
    createUserCompany(input: $input)
  }
`;

export const REQUEST_FORGOT_PASSWORD = `
mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
`

export const SET_RESET_PASSWORD = `
mutation setPassword($hash: String!, $password: String!) {
  setPassword(hash: $hash, password: $password)
}
`;

export const DELETE_EMPRESA = `
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const ADD_CLIENT = `
  mutation addClient($input: ClientInput) {
    addClient(input: $input) {
      id
      nombres
      apellidos
      ci
      fecha_nacimiento
      telefono
      municipio
      departamento
      direccion
      sexo
      edad
    }
  }
`;

export const EDIT_CLIENT = `
  mutation editClient($input: ClientEditInput) {
    editClient(input: $input) {
      id
      nombres
      apellidos
      ci
      fecha_nacimiento
      telefono
      municipio
      departamento
      direccion
      sexo
      edad
    }
  }
`;

export const UPDATE_CLIENT = `
  mutation updateClient($input: ClientInput) {
    updateClient(input: $input) {
      nombres
      apellidos
      ci
      fecha_nacimiento
      sexo
      edad
      telefono
      municipio
      departamento
      direccion
    }
  }
`;

export const ADD_FICHA = `
  mutation addFicha($input: FichaInput) {
    addFicha(input: $input) {
      id
      fechaSolicitud
      codigo
      muestraControl
      tipoFicha
      tipoDeMuestra
      inicioSintomas
      resultado
      fechaReporte
      observacion
      informedSNIS
      informedSNISBy
    }
  }
`;

export const EDIT_FICHA = `
  mutation editFicha($input: FichaEditInput) {
    editFicha(input: $input) {
      codigo
      fechaSolicitud
      codigo
      muestraControl
      resultado
      createdBy {
        nombre
      }
      editedBy {
        nombre
      }
    }
  }
`;

export const UPDATE_TIPO_FICHA = `
mutation editTipoFicha($id: ID!, $tipoFicha: String!) {
  editTipoFicha(id: $id, tipoFicha: $tipoFicha) {
    codigo
  }
}
`;

export const SET_RESULT = `
  mutation setResult($input: FichaSetResultInput) {
    setResult(input: $input) {
      codigo
      fechaSolicitud
      codigo
      muestraControl
      resultado
      createdBy {
        nombre
      }
      editedBy {
        nombre
      }
    }
  }
`;

export const SET_CONCILIACION = `
  mutation setConciliacionFicha($input: FichaConciliacionInput) {
    setConciliacionFicha(input: $input) {
      codigo
    }
  }
`;


export const REMOVE_CLIENT = `
  mutation deleteClient($id: ID) {
    deleteClient(id: $id)
  }
`;

export const REMOVE_FICHA = `
  mutation deleteFicha($id: ID) {
    deleteFicha(id: $id)
  }
`;

export const UPDATE_EMPRESA = `
mutation updateUser($input: UserEditInput) {
  updateUser(input: $input) {
    id
    email
    nombre
    apellido
    role
    approved
    compania
    keywords
  }
}
`
export const UPDATE_PRIVILEGES = `
  mutation updateSystemUserPrivilegesUser($input: UserPrivilegesInput) {
    updateSystemUserPrivilegesUser(input: $input) {
      id
      nombre
      role
      approved
    }
  }
`;
