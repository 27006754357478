import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";
import WaterMark from "../informes/WaterMark";

const ReportCAB = ({ estudio, paciente, qrValue }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    qrImageStyle,
  } = stylesReport;

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud)
          .utc()
          .format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
        spaceMedico={true}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Prueba:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.nombre} `}</Text>
            </Text>
            <Text style={[textStyle, { marginLeft: 31 }]}>
              <Text
                style={dataTextStyle}
              >{`${estudio.estudioID.description} `}</Text>
            </Text>
            <Text style={textStyle}>
              Técnica:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.metodo} `}</Text>
            </Text>
            <Text style={textStyle}>
              Tipo de Muestra:
              <Text
                style={dataTextStyle}
              >{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              SARS-Cov2 (COVID-19) :
              <Text style={dataTextStyle}>
                {` ${getValueField(estudio.camposEstudio, "sars")} `}{" "}
              </Text>
            </Text>
            <Text style={textStyle}>
              Influenza A :
              <Text style={dataTextStyle}>
                {` ${getValueField(estudio.camposEstudio, "influ_a")} `}{" "}
              </Text>
            </Text>
            <Text style={textStyle}>
              Influenza B :
              <Text style={dataTextStyle}>
                {` ${getValueField(estudio.camposEstudio, "influ_b")} `}{" "}
              </Text>
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 10 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            {getValueField(estudio.camposEstudio, "observaciones") !== "" ? (
              <Text style={textStyle}>
                Observaciones:
                <Text style={dataTextStyle}>{` ${getValueField(
                  estudio.camposEstudio,
                  "observaciones"
                )} `}</Text>
              </Text>
            ) : null}
          </View>
        </View>
      </View>
      <FirmaEspecialista
        nombreEspecialista="sebastian"
        styleFromParent={{ marginTop: 20 }}
        isSigned={estudio.isSigned}
      />
      <View style={[mainContainerStyle]}>
        <View style={dataContainerStyle}>
          <Image source={{ uri: qrValue }} style={[qrImageStyle]} />
        </View>
      </View>
      <Footer />
    </Page>
  );
};

export default ReportCAB;
