import createDataContext from './createDataContext';

const INITIAL_STATE = {
  PCR: [],
  ELISA: [],
  PACIENTES: []
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set_PRC': {
      return { ...state, PCR: action.payload }
    }
    case 'set_ELISA': {
      return { ...state, ELISA: action.payload }
    }
    case 'set_pacientes': {
      return { ...state, PACIENTES: action.payload }
    }
    case 'clear': {
      return INITIAL_STATE;
    }
    default: return state;
  }
}

const clearPruebas = dispatch => () => {
  dispatch({ type: 'clear' })
}

const setPCR = dispatch => (value) => {
  dispatch({ type: 'set_PRC', payload: value })
}

const setELISA = dispatch => (value) => {
  dispatch({ type: 'set_ELISA', payload: value })
}

const setPruebas = dispatch => (value, codigoPrueba) => {
  if (value.length > 0) {
    if (codigoPrueba === "C") {
      dispatch({ type: 'set_PRC', payload: value })
    } else if (codigoPrueba === "E") {
      dispatch({ type: 'set_ELISA', payload: value })
    }
  }
}

const setPacientes = dispatch => (value) => {
  dispatch({ type: 'set_pacientes', payload: value })
}

export const { Provider, Context } = createDataContext(authReducer,
  {
    clearPruebas,
    setPCR,
    setELISA,
    setPruebas,
    setPacientes
  },
  INITIAL_STATE
)