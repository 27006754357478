import React, { useEffect, useState } from 'react';
import { Row, Col, Tag, Button, Empty, Typography } from 'antd';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';
import OldDownloadButton from '../components/DownloadButton';
import DownloadButton from '../components/informes/DownloadButton';
import moment from 'moment';
import { getValueField } from '../utils/common'
import AttachmentFile from '../molecules/AttachmentFile';


const optionsTab = {
  reactiveData: true,
  layout: "fitColumns",
  pagination: "local",
  paginationSize: 30,
}

const InformesEmpresaNuevo = ({ test, query, variablesQuery, methodQuery, nombreEmpresa, dataInformes }) => {

  const [results, setResults] = useState([]);
  let refTab = React.createRef();

  useEffect(() => {
    updateUI(dataInformes);
  }, [dataInformes]);


  const updateUI = (data) => {
    setTimeout(() => {
      setResults(data)
    }, 500)
  }

  const ButtonsAction = (props) => {
    const rowData = props.cell._cell.row.data;
    if (rowData.testID) {
      return (
        <>
          <OldDownloadButton rowData={rowData} test={rowData.testID} />
          {rowData.attachmentFiles.map((item) => (
            <AttachmentFile
              key={item.id}
              iconStyle={{ fontSize: '18px' }}
              style={{ verticalAlign: 'middle', marginLeft: '10px' }}
              mimeType={item.mimeType}
              url={item.url}
            />
          ))}
        </>
      )
    }
    return (
      <>
        <DownloadButton size="small" disabled={rowData.estadoEstudio !== "PROCESADO" ? true : false} estudio={rowData} cliente={rowData.ordenID.clientID} />
        {rowData.attachmentFiles.map((item) => (
          <AttachmentFile
            key={item.id}
            iconStyle={{ fontSize: '18px' }}
            style={{ verticalAlign: 'middle', marginLeft: '10px' }}
            mimeType={item.mimeType}
            url={item.url}
          />
        ))}
      </>
    );
  }

  const LabelResult = (props) => {
    let color = 'green';
    let labelStatus = 'DISPONIBLE';
    const rowData = props.cell._cell.row.data;

    if (rowData.testID) {
      if (!rowData.resultado) {
        color = 'orange';
        labelStatus = 'NO DISPONIBLE';
      }
      return (
        <>
          <Tag color={color}>
            {labelStatus}
          </Tag>
        </>
      )

    } else {

      if (rowData.estadoEstudio != 'PROCESADO') {
        color = 'orange';
        return (
          <Tag color={color}>
            {rowData.estadoEstudio}
          </Tag>
        )
      }

      return (
        <Tag color={color}>
          {labelStatus}
        </Tag>
      )
    }
  }

  const columnsTable = () => {
    if (nombreEmpresa === "Shell Bolivia") {
      return [
        {
          title: 'Nombre Estudio',
          field: 'estudioNombre',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              return data.testID.nombre;
            }
            return data.estudioID.nombre;
          },
        },
        {
          title: "Empresa",
          field: 'tipoFichaDetalle',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              return data.tipoFicha;
            }
            const nameEmpresa = data.ordenID.contratista ? `${nombreEmpresa}/${data.ordenID.contratista}` : nombreEmpresa;
            return nameEmpresa;
          },
        },
        {
          title: "Fecha toma de muestra",
          field: 'fechaTomaDeMuestra1',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              return data.fechaSolicitud;
            }
            return moment(data.fechaTomaDeMuestra).utc().format("DD/MM/YYYY");
          },
        },
        {
          title: "Codigo",
          field: 'codigo',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              return data.codigo;
            }
            return `${data.codigoEstudio}${data.estudioNumero}`;
          },
        },
        {
          title: "Nombres",
          field: 'clientID.nombres',
          cellVertAlign: 'center',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              return data.clientID.nombres;
            }
            return data.ordenID.clientID.nombres;;
          },
        },
        {
          title: "Apellidos",
          field: 'clientID.apellidos',
          cellVertAlign: 'center',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              return data.clientID.apellidos;
            }
            return data.ordenID.clientID.apellidos;;
          },
        },
        {
          title: "CI",
          field: 'clientID.ci',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              return data.clientID.ci;
            }
            return data.ordenID.clientID.ci;;
          },
        },
        {
          title: "Resultado",
          field: 'resultado-formateado',
          headerFilter: "input",
          headerFilterPlaceholder: "Filtrar",
          mutator: function (value, data, type, mutatorParams, cell) {
            if (data.testID) {
              if (data.testID.codigoPrueba === 'C' || data.testID.codigoPrueba === 'CS') {
                return data.resultado;
              }
              return "";
            }
            if (data.estadoEstudio !== "PROCESADO") {
              return ""
            }
            if (data.codigoEstudio === "PCRC" || data.codigoEstudio === "PCRCS" || data.codigoEstudio === "ASC") {
              return getValueField(data.camposEstudio, 'resultado')
            }

            return "-"
          },
        },
        {
          title: "Estatus",
          field: 'resultado',
          headerSort: false,
          formatter: reactFormatter(
            <LabelResult />
          ),
        },
        {
          title: "",
          field: "custom",
          hozAlign: "center",
          headerSort: false,
          formatter: reactFormatter(
            <ButtonsAction />
          ),
        }
      ]
    }
    return [
      {
        title: 'Nombre Estudio',
        field: 'estudioNombre',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
        mutator: function (value, data, type, mutatorParams, cell) {
          if (data.testID) {
            return data.testID.nombre;
          }
          return data.estudioID.nombre;
        },
      },
      {
        title: "Fecha toma de muestra", field: 'fechaTomaDeMuestra1', headerFilter: "input", headerFilterPlaceholder: "Filtrar",
        mutator: function (value, data, type, mutatorParams, cell) {
          if (data.testID) {
            return data.fechaSolicitud;
          }
          return moment(data.fechaTomaDeMuestra).utc().format("DD/MM/YYYY");
        },
      },
      {
        title: "Codigo",
        field: 'codigo',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
        mutator: function (value, data, type, mutatorParams, cell) {
          if (data.testID) {
            return data.codigo;
          }
          return `${data.codigoEstudio}${data.estudioNumero}`;
        },
      },
      {
        title: "Nombres",
        field: 'clientID.nombres',
        cellVertAlign: 'center',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
        mutator: function (value, data, type, mutatorParams, cell) {
          if (data.testID) {
            return data.clientID.nombres;
          }
          return data.ordenID.clientID.nombres;;
        },
      },
      {
        title: "Apellidos",
        field: 'clientID.apellidos',
        cellVertAlign: 'center',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
        mutator: function (value, data, type, mutatorParams, cell) {
          if (data.testID) {
            return data.clientID.apellidos;
          }
          return data.ordenID.clientID.apellidos;;
        },
      },
      {
        title: "CI",
        field: 'clientID.ci',
        headerFilter: "input",
        headerFilterPlaceholder: "Filtrar",
        mutator: function (value, data, type, mutatorParams, cell) {
          if (data.testID) {
            return data.clientID.ci;
          }
          return data.ordenID.clientID.ci;;
        },
      },
      {
        title: "Estatus",
        field: 'resultado',
        headerSort: false,
        formatter: reactFormatter(
          <LabelResult />
        ),
      },
      {
        title: "",
        field: "custom",
        hozAlign: "center",
        headerSort: false,
        formatter: reactFormatter(
          <ButtonsAction />
        ),
      }
    ]
  }

  return (
    <Row type="flex" style={{ backgroundColor: 'white' }}>
      <Col span={24}>
        {
          results.length > 0 ?
            <ReactTabulator
              ref={ref => (refTab = ref)}
              columns={columnsTable()}
              data={results}
              options={optionsTab}
            /> : null
        }
      </Col>
    </Row>
  )
}

export default InformesEmpresaNuevo;